// eslint-disable-next-line no-unused-vars
// const emit = function () {}
export default class ModelProto {
  static title = 'Kanban'
  static plural = 'Kanbans'
  static icon = 'mdi-table'
  static menuId = 1

  static template () {
    return []
  };

  static formConfig = []
  static getListSchema = []

  gettype () {
    return this.constructor.type
  }

  // couchdb view definitions
  static ddoc = {
    _id: '_design/kanban',
    language: 'javascript',
    views: {
      kanboardssorted: {
        map: "function (doc) {\n if (doc.type === 'project_kanboard') {\n emit(doc.sort, null); \n}\n}"
      },
      priotasks: {
        map: "function (doc) {\n if ((doc.type === 'project_task') && (doc.priority > 0)) {\n emit([doc.priority, doc.sort], null);\n}\n}"
      },
      projects: {
        map: "function (doc) {\n if (doc.type === 'project) {\n emit(doc._id, { _id: doc.client_id, name: doc.name, project_id: doc.project_id }, null); \n}\n}"
      },
      tasks: {
        map: "function (doc) {\n if (doc.type === 'project_task') {\n emit(doc.project_uuid, null); \n}\n}"
      }
    }
  }

  // couchdb view definitions
  static ddocOrig = {
    _id: '_design/tables',
    views: {
      project_client: { map: 'function(doc){ if (doc.type === \'project_client\') {emit(doc._id)}}' },
      project: {
        map: 'function (doc) {\n  if (doc.type === \'project\') {\n        emit(doc._id, { _id: doc.client_id, name: doc.name, project_id: doc.project_id }, null)\n      }\n}'
      },
      project_task: {
        map: 'function (doc) {\n  if (doc.type === \'project_task\') {\n        emit(doc._id, { _id: doc.project_uuid, name: doc.name, progress: doc.progress, rate_hour: doc.rate_hour }, null)\n      }\n}'
      },
      medication: {
        map: 'function (doc) {\n  if(doc.type === \'medication\'){\n          emit(doc._id)\n}\n}'
      }

    }
  }

  static async putDddocs () {
    // const doc = await pouch.get('_design/tables')
    // doc.views = this.ddocOrig.views
    // pouch.put(doc)
    await this.vue.$pouch.put(this.ddoc)
    await this.vue.$pouch.put(this.ddocOrig)
    // await pouch.query('kanban/priotasks').then((p) => {
    //   console.log(p)
    // })
    // console.log(await pouch.get('_design/kanban'))
  }

  static async getListData () {
    const res = await this.vue.$pouch.query('tables/' + this.type, { include_docs: true })
    return { docs: res.rows }
  }

  static async beforeDelete (item) {

  }
}
