<template lang="pug">
  .kanban.ma-0.pa-0(@mousedown='cancelEdit' )
    types-menu(ref="typesMenu"  :items="typesList" v-on:contextSelect="typesSelect" @mousedown.native.stop)
    kanban-menu(v-bind:title="pagetitle" ref="kbmenu")
    v-spacer

    // tool buttons
    portal(to="topTools" v-if="!isMobile || isMobileLandscape")
      v-btn.transparent(v-on:click='updateTitleFont(5)'    )
        v-icon(small) mdi-plus
      v-btn.transparent(v-on:click='updateTitleFont(-5)'    )
      v-btn.transparent(v-on:click='updateTitleFont(-5)'    )
        v-icon(small) mdi-minus
      kanban-filter-dialog
      v-btn.transparent(v-on:click='toggleTitlesOnly'   )
        v-icon( :color="!getSettings.titlesOnly?'green':''") mdi-format-pilcrow
      v-btn.transparent(v-on:click='toggleShowInfo'   )
        v-icon( :color="getSettings.showInfo?'green':''") mdi-information-outline
      kanban-project-menu.d-inline-block(v-bind="{kanboard:kanboard}" v-on:projectChange="syncDb")
    portal(to="pagetools"  v-if="isMobile && !isMobileLandscape")
        v-btn.transparent(v-on:click='updateTitleFont(5)' icon="mdi-plus" ).center
          v-icon(small) mdi-plus
        v-btn.transparent(v-on:click='updateTitleFont(-5)' ).center
          v-icon(small) mdi-minus
        kanban-filter-dialog.transparent(:fromBottom="isMobile && !isMobileLandscape")
        v-btn.transparent(v-on:click='toggleTitlesOnly'   ).center
          v-icon( :color="!getSettings.titlesOnly?'green':''") mdi-format-pilcrow
        v-btn.transparent(v-on:click='toggleShowInfo'   ).center
          v-icon( :color="getSettings.showInfo?'green':''") mdi-information-outline
        kanban-project-menu(v-bind="{kanboard:kanboard}" v-on:projectChange="syncDb")

    div(v-for='(project, i) in projects' :key='project._id')
      .name
        .arrow(v-if='i>0' :data-sort='i' :data-id='project._id' @click='moveup(project._id,i)').cursorMoveUp
          | ↑
        h4(v-if="!isPrioPage") {{ project.name }}
        h4(v-if="isPrioPage") {{ $t('kanban.all_projects') }}
      .row.pa-5
        .col-sm(v-for='column in columns' :key='column.key' v-show="colShow(column.key)" :data-progress='column.key'  :class="(isMobile && !isMobileLandscape)?'full-space':'event-space'" )
          // Column Header
          .kanban-column-header.elevation-2
            .header.unselectable
              span.name {{column.label}}
              span.plus(v-if="(column.key==='progress0')||(column.key==='progress1')||(column.key==='progress2')")
                v-icon.cursorPlus(small v-on:click='createItem(project._id,column.key)') mdi-plus
              span.plus(v-if="((column.key==='progress99') && tasks.filter(item=>(item.progress==='progress99')).length)")
                v-icon.cursorPlus(small v-on:click='deleteArchived(project._id)' color="red" ) mdi-delete

          // Column Content
          draggable.list-group(
            :id="'list-group-'+project._id"
            group='ALLPROJECTS'
            :data-progress='column.key'
            :data-project='project._id'
            :draggable="editrecord._id===0?'.item':false"
            animation='300'
            handle=".handle"
            v-on:end='dragend($event)')

            v-card.kanban-card(style="position:relative"
              v-show="typeShow(element.taskType)  && urgencyShow(element.urgency)"
              :class="getCardClass(element)"
              v-for='element in getTasks(column.key, project._id)'
              :key='element._id' :data-id='element._id'
            )
              // TITLE & TEXT
              v-card( flat v-if='(editrecord._id!==element._id) || useDialog' style="padding-bottom: 0px"  )
                // Project Name
                .unselectable.text-no-wrap(v-if="isPrioPage" style="font-size:70%" class="truncated")
                  | {{getProjectName(element.project_uuid)}}
                // Task Name
                .title.unselectable.mr-3.editCursor.card-title(:style="titleStyle"  @mouseup.stop='startedit($event,element)'
                  :class="getSettings.titlesOnly?'truncated':''"
                )
                  | {{element.name}}
                // description
                v-expand-transition(:duration="{ enter: 150, leave: 150 }")
                  div.text-sm-body-2.unselectable.description(v-if="!getSettings.titlesOnly" :linkify="false"  v-html="markDown(element.description)")

              // Prio Button
              prio-button(:model="element" :mouseup-handler="togglePrio")
              type-icon(:icon="getTypeIcon(element.taskType)" icon-class="getTypeClass(element.taskType)" )

              // Drag Element
              v-card( flat
              class="handle"
                v-if='editrecord._id!==element._id'
                style="position:absolute;left:-5px; top:0; bottom:0px;width:20%; height: 35px;")

              // Info Area
              v-expand-transition(:duration="{ enter: 150, leave: 150 }")
                v-card( flat style='position:relative; bottom: -4px; ' v-show="getSettings.showInfo" v-if="element.updateInfo!==undefined").card-info.unselectable
                  span {{element.updateInfo.updatedBy}}
                  span.ml-2 {{moment(element.updateInfo.updatedAt).format('YYYY-MM-D HH:mm')}}
              v-card( flat v-if='editrecord._id===element._id')
                span(v-if="useDialog") {{$t('kanban.editing')}} ...
                kanban-task-card-edit(v-if="!useDialog" :editrecord="editrecord" :remove="remove")
            .btn-group.list-group-item(slot='header' role='group' aria-label='Basic example' style='min-height: 7px;')
    kanban-task-dialog-edit(v-if="useDialog" :editrecord="editrecord" :update="update" :cancelEdit="cancelEdit" :remove="remove"  ref="editDialog")

</template>
<script>

import { getNow, validation } from '../../utils'
import moment from 'moment'
import { isAndroid } from 'mobile-device-detect'
import { v4 as uuid } from 'uuid'
import marked from 'marked'

import draggable from 'vuedraggable'
import ProjectTask from '../../models/ProjectTask'
import KanbanMenu from './KanbanMenu'
import KanbanProjectMenu from './KanbanProjectMenu'
import KanbanTaskCardEdit from './KanbanTaskCardEdit'
import KanbanTaskDialogEdit from './KanbanTaskDialogEdit'
import TypesMenu from '../TypesMenu'
import KanbanFilterDialog from './KanbanFilterDialog'
import PrioButton from './PrioButton'
import TypeIcon from './TypeIcon'

export default {
  name: 'kanban',
  // display: 'Two list header slot',
  components: {
    KanbanProjectMenu,
    KanbanMenu,
    KanbanTaskCardEdit,
    KanbanTaskDialogEdit,
    KanbanFilterDialog,
    TypesMenu,
    draggable,
    PrioButton,
    TypeIcon
  },
  data () {
    return {
      editrecord: { _id: 0 },
      boardRoutes: [],
      kanboard: {},
      projects: [],
      tasks: [],
      projectNames: [], // needed for Prio Board, since it has All Projects, but no own project
      typesList: ProjectTask.getTypesList(),
      urgencies: ProjectTask.getUrgenciesList()
    }
  },

  async beforeMount () {
    ProjectTask.vue = this
    await this.syncDb()
    window.addEventListener('keyup', async (evt) => {
      if (evt.code === 'Escape') {
        await this.cancelEdit()
      }
      if (evt.code === 'NumpadEnter') {
        if (this.editrecord._id !== 0) {
          await this.update(this.editrecord)
        }
      }
    })
  },
  watch: {
    async '$route' () {
      await this.syncDb()
    }
  },
  computed: {
    titleStyle () {
      let settings = this.$store.state.appSettings.taskTitleSize ?? 100
      if (this.isMobileLandscape) {
        settings = this.$store.state.appSettings.taskTitleSizeLandscape ?? 100
      }
      return 'font-size: ' + settings + '% !important'
    },
    useDialog () {
      // return this.isMobile
      return true
    },
    moment () {
      return moment
    },
    columnButtonPosition () {
      const top = (this.breakpointWidth < 960) ? 'top: 5px' : 'top:53px'
      return 'position:absolute; right:20px; z-index: 2;' + top
    },
    isMobileLandscape: function () {
      return this.isMobile && (window.innerWidth > window.innerHeight)
    },
    isMobile () {
      return this.$vuetify.breakpoint.width < 920
    },
    breakpointWidth () {
      return this.$vuetify.breakpoint.width
    },
    isAndroid () {
      return isAndroid
    },
    rules () {
      return validation
    },

    columns () {
      const cols = []
      cols.push({
        key: 'progress0',
        label: this.$t('kanban.backlog')
      })
      // }
      cols.push({
        key: 'progress1',
        label: this.$t('kanban.ready')
      })
      cols.push({
        key: 'progress2',
        label: this.$t('kanban.priority')
      })
      cols.push({
        key: 'progress3',
        label: this.$t('kanban.in-progress')
      })
      cols.push({
        key: 'progress4',
        label: this.$t('kanban.testing')
      })
      cols.push({
        key: 'progress5',
        label: this.$t('kanban.finished')
      })
      cols.push({
        key: 'progress99',
        label: this.$t('kanban.archive')
      })
      return cols
    },

    isPrioPage () {
      return this.$route.params.id === 'prio'
    },
    pagetitle () {
      if (this.isPrioPage) {
        return this.$t('kanban.prio')
      }
      return this.kanboard.name
    },

    currentPage () {
      return this.boardRoutes.find(x => {
        return this.$route.params.id === x.dest.params.id
      })
    },
    getSettings () {
      return this.$store.state.appSettings
    }
  },
  pouch: {
    // tasks () { return { database: 'pouchtest', selector: { type: 'projects' } } }
  },
  methods: {
    getCardClass (element) {
      return 'list-group-item item kanban-item elevation-2 urgency-' + element.urgency + (this.getSettings.titlesOnly ? ' titles-only' : ' show-details')
    },
    colShow (key) {
      return this.$store.state.appSettings.progressVisible.indexOf(key) > -1
    },
    typeShow (key) {
      if (this.$store.state.appSettings.typesVisible.length === 0) {
        return true
      }
      return this.$store.state.appSettings.typesVisible.indexOf(key) > -1
    },
    urgencyShow (key) {
      if (this.$store.state.appSettings.urgenciesVisible.length === 0) {
        return true
      }
      return this.$store.state.appSettings.urgenciesVisible.indexOf(parseInt(key)) > -1
    },
    markDown (text) {
      if (text) {
        return marked(text, {
          smartLists: true,
          silent: true,
          sanitize: true,
          gfm: true
        })
      }
      return text
    },
    async syncDb () {
      await this.$syncDb(this.getProjects)
    },
    async cancelEdit () {
      await this.resetEdit()
      await this.syncDb()
    },
    async getProjects () {
      const id = this.$route.params.id || 'prio'
      if (id === 'prio') {
        const defaultProject = await this.getDefaultProject()

        await this.$pouch.createIndex({
          index: {
            fields: ['type', 'priority', 'sort']
          }
        })
        // const tasks = await this.$pouch.query('kanban/priotasks', { include_docs: true })
        const tasks = await this.$pouch.find({ selector: { type: { $eq: 'project_task' }, priority: { $gt: 0 } } })
        this.tasks = tasks.docs

        const projs = await this.$pouch.find({ selector: { type: { $eq: 'project' } } })

        this.projectNames = projs.docs.filter(x => (x !== null))

        this.projects = [
          defaultProject
        ]
      } else {
        this.kanboard = await this.$pouch.get(this.$route.params.id)
        if (this.kanboard.projects) {
          const projs = await this.$pouch.allDocs({
            keys: this.kanboard.projects,
            include_docs: true
          })
          const projrows = projs.rows.filter(x => x.value && !x.value.deleted)
          this.projects = projrows.map(x => x.doc)
          // this.projects.sort(this.simpleSort)
          const tasks = await this.$pouch.query('kanban/tasks', {
            keys: this.kanboard.projects,
            include_docs: true
          })
          this.tasks = tasks.rows.map(x => x.doc)
          this.tasks.sort(this.simpleSort)
        }
      }
      if (this.$refs.kbmenu) {
        await this.$refs.kbmenu.getBoardRoutes()
      }
    },
    getTasks (key, projectUuid) {
      return this.tasks.filter(x => {
        return (x.progress === key) && ((x.project_uuid === projectUuid) || (this.$route.params.id === 'prio'))
      })
    },
    contextSelect (e, item, data) {
      this[item.action](data)
    },
    async typesSelect (e, item, record) {
      record.taskType = item.value
      await this.update(record)
    },
    onTitleFocus (ev) {
      // eslint-disable-next-line no-prototype-builtins
      if (this.editrecord.hasOwnProperty('isNew')) {
        ev.target.select()
      }
    },

    getProjectName (id) {
      const proj = this.projectNames.find(item => item._id === id)
      if (!proj) {
        return ''
      }
      return proj.name
    },
    sortProjects (a, b) {
      if (a.pivot.sort < b.pivot.sort) {
        return -1
      }
      if (a.pivot.sort > b.pivot.sort) {
        return 1
      }
      return 0
    },
    simpleSortPrio (a, b) {
      if (a.priority === b.priority) {
        // Price is only important when cities are the same
        return b.sort - a.sort
      }
      return a.priority > b.priority ? 1 : -1
    },
    simpleSort (a, b) {
      return a.sort > b.sort ? 1 : -1
    },
    async moveup (uuid, ind) {
      const uuids = this.kanboard.projects
      this.kanboard.projects = []
      uuids.forEach((item, i) => {
        if (i === (ind - 1)) {
          this.kanboard.projects.push(uuid)
          this.kanboard.projects.push(item)
        } else if (i !== ind) {
          this.kanboard.projects.push(item)
        }
      })
      await this.$upsert(this.kanboard)
      this.$syncDb(this.getProjects)
    },
    async resetEdit () {
      this.$refs.editDialog.dialog = false
      this.$nextTick(() => {
        this.editrecord = { _id: 0 }
      }, 100)
    },
    async startedit (ev, element) {
      if (ev.metaKey === true) {
        await this.togglePrio(element)
      }
      this.initEdit(element)
    },
    initEdit (element) {
      this.editrecord = { ...element } // this must be cloned or trouble!
      if (this.useDialog) {
        this.$refs.editDialog.dialog = true
      }
    },
    async dragend (ev) {
      const changed = []
      const progress = ev.to.dataset.progress
      const projectTo = ev.to.dataset.project
      const projectFrom = ev.from.dataset.project
      const sortcol = (this.$route.params.id === 'prio') ? 'priority' : 'sort'
      let order = 0
      for (const item of ev.to.childNodes) {
        const itemSort = item[sortcol]
        ++order
        if (itemSort !== order) { // only save if changed
          const id = item.dataset.id
          if (id !== undefined) {
            const record = await this.$pouch.get(id)
            if (record !== null) {
              if (projectTo !== projectFrom) {
                record.project_uuid = projectTo
              }
              record.progress = progress
              record[sortcol] = order
              changed.push(record)
            }
          }
        }
      }
      for (const record of changed) {
        await this.$upsert(record)
      }
      await this.resetEdit()
      this.$syncDb(this.getProjects)
    },
    async togglePrio (record) {
      record.priority = (record.priority > 0) ? 0 : (record.sort + 1)
      await this.update(record)
    },
    async archive (record) {
      record.progress = 'progress99'
      await this.update(record)
    },
    async update (record) {
      if (record.name === '') {
        return
      }
      if (!record._id || (record._id === 0)) {
        return
      }
      // TODO this is temporary
      if (!record.progress) {
        record.progress = 'progress0'
      }
      // TODO END this is temporary

      await this.$upsert(record)
      await this.resetEdit()
      await this.$syncDb(this.getProjects)
    },

    async remove (record) {
      const res = await this.$dialog.confirm({
        title: this.$t('kanban.Really delete?'),
        text: '',
        actions: {
          false: this.$t('kanban.cancel'),
          true: this.$t('kanban.delete')
        }
      })
      if (res) {
        await this.$remove(record)
      }
      await this.resetEdit()
      await this.$syncDb(this.getProjects)
    },
    async deleteArchived () {
      const res = await this.$dialog.confirm({
        title: this.$t('kanban.delete_archived'),
        text: '',
        actions: {
          false: this.$t('kanban.cancel'),
          true: this.$t('kanban.delete')
        }
      })
      if (res) {
        const del = this.tasks.filter(item => (item.progress === 'progress99'))
        await Promise.all(del.map(async record => {
          await this.$remove(record)
        }))
        await this.resetEdit()
        await this.$syncDb(this.getProjects)
      }
    },
    async createItem (projectUuid, progress) {
      const project = await this.$pouch.get(projectUuid)
      if (project) {
        const newtask = {
          _id: uuid(),
          isNew: true,
          type: 'project_task',
          project_uuid: project._id,
          name: '00-NewTask',
          description: '',
          progress: progress,
          urgency: 0,
          priority: this.isPrioPage ? 1 : 0,
          updateInfo: {
            updatedBy: '',
            updatedAt: '',
            author: ''
          },
          createdAt: getNow().toISOString(),
          sort: 1
        }
        this.tasks.unshift(newtask)
        await this.startedit({}, newtask)
      }
    },
    async getDefaultProject () {
      await this.$pouch.createIndex({
        index: {
          fields: ['isDefault', 'type']
        }
      })
      const res = await this.$pouch.find({
        selector: {
          isDefault: { $gt: null },
          type: { $eq: 'project' }
        }
      })
      return res.docs[0]
    },
    async toggleTitlesOnly (ev, key) {
      const settings = { ...this.$store.state.appSettings }
      settings.titlesOnly = !settings.titlesOnly
      await this.$store.commit('setAppSettings', settings)
    },
    async toggleShowInfo (ev, key) {
      const settings = { ...this.$store.state.appSettings }
      settings.showInfo = !settings.showInfo
      await this.$store.commit('setAppSettings', settings)
    },
    async updateTitleFont (dir = 1) {
      if (this.isMobileLandscape) {
        const settings = { ...this.$store.state.appSettings }
        settings.taskTitleSizeLandscape = parseInt(settings.taskTitleSizeLandscape)
        settings.taskTitleSizeLandscape = settings.taskTitleSizeLandscape ?? 100
        settings.taskTitleSizeLandscape += parseInt(dir)
        settings.taskTitleSizeLandscape = Math.min(settings.taskTitleSizeLandscape, 140)
        settings.taskTitleSizeLandscape = Math.max(settings.taskTitleSizeLandscape, 70)
        await this.$store.commit('setAppSettings', settings)
      } else {
        const settings = { ...this.$store.state.appSettings }
        settings.taskTitleSize = parseInt(settings.taskTitleSize)
        settings.taskTitleSize = settings.taskTitleSize ?? 100
        settings.taskTitleSize += parseInt(dir)
        await this.$store.commit('setAppSettings', settings)
      }
    },
    getTypeIcon (type) {
      if (!type) {
        return ''
      }
      const menuItem = this.typesList.find(m => (m.value === type))
      if (!menuItem) {
        return ''
      }
      return menuItem.icon
    },
    getTypeClass (type) {
      const menuItem = this.typesList.find(m => (m.value === type))
      if (!menuItem) {
        return ''
      }
      return menuItem.class
    }
  }

}
</script>
<style scoped lang="sass">
.card-info
  font-size: 70%
.description
  cursor: auto
  padding: 10px 0

  &:empty
    display: none

.full-space
  .card-info
    font-size: 75%

  .kanban-card
    font-size: 130%
    padding-bottom: 7px

  min-width: 100%
  min-height: 40px

.event-space
  flex-basis: 0
  flex-grow: 1
  min-width: 5px

.card-title
  transition: max-height 300ms ease-out
  max-height: 100px

.truncated
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis
  min-width: 20px
  max-height: 40px

</style>
<style lang="sass">
.urgency
  margin-top: 5px !important

.kanban-textarea
  font-size: 85%
  line-height: 1.2 !important

  .v-text-field__details
    display: none

.v-input--radio-group--row .v-input--radio-group__input
  color: green !important
  flex-wrap: nowrap !important

  legend
    wrap-after: flex !important

input.task-list-item-checkbox, input.task-list-item-checkbox:checked
  background-color: transparent !important
  color: transparent !important

.theme--dark, .theme--light
  &.success--text i
    color: var(--v-success-base)

  &.info--text i
    color: var(--v-info-base)

  &.warning--text i
    color: var(--v-warning-base)

  &.error--text i
    color: var(--v-error-base)

.theme--dark
  .editCursor
    cursor: url('/img/pencil.svg') -56 56, auto

  .handle
    cursor: url('/img/drag-variant.svg') 12 12, move !important

  .toggleCursor
    cursor: url('/img/menu-open.svg') 12 12, auto

  .cursorMoveUp
    cursor: url('/img/elevator-up.svg') 12 12, auto !important

  .cursorFlag
    cursor: url('/img/flag-outline.svg') 12 12, auto !important

  .cursorPlus
    cursor: url('/img/plus-outline.svg') -56 56, auto !important

.theme--light
  .editCursor
    cursor: url('/img/pencil-L.svg') -56 56, auto

  .handle
    cursor: url('/img/drag-variant-L.svg') 12 12, move !important

  .toggleCursor
    cursor: url('/img/menu-open-L.svg') 12 12, auto

  .cursorMoveUp
    cursor: url('/img/elevator-up-L.svg') 12 12, auto !important

  .cursorFlag
    cursor: url('/img/flag-outlineL.svg') 12 12, auto !important

  .cursorPlus
    cursor: url('/img/plus-outlineL.svg') -56 56, auto !important

.kanban
  margin: -8px -12px 0 -12px

  .name
    position: relative
    padding-left: 12px !important

    h4
      font-size: 85%
      padding-bottom: 0
      position: relative
      top: 12px

    .arrow
      opacity: 0
      font-size: 120%
      cursor: pointer
      z-index: 5
      width: 250px !important
      transition: opacity .2s ease-in-out
      height: 30px !important
      padding-left: 10px
      position: absolute
      left: -15px
      top: 40px

      &:hover
        opacity: 1

  .nav
    margin-left: -17px
    background-color: transparent !important

    .v-toolbar__content
      a
        font-weight: 700 !important

      .v-btn span
        opacity: 0.4

      .v-btn--active
        background-color: transparent !important

        span
          opacity: 1

        &:hover:before, &:before
          display: none

  .kanban-item
    .v-card, .v-sheet
      background-color: transparent !important
    .body
      font-size: 95% !important

    .title
      line-height: 1.1em
      padding-top: 7px
      font-weight: bold
    padding: 5px 10px
    margin-bottom: 10px
    min-height: 50px

  .kanban-column-header
    text-transform: uppercase
    font-size: 85%
    padding: 5px 13px !important
    height: 30px
    display: flex
    flex-flow: column
    justify-content: center
    margin: 0px -8px 3px -8px

    .header
      font-weight: bold
      font-size: 80%
      width: 100%
      text-align: left

      .plus
        float: right
        position: relative
        right: -5px
        display: inline-block
        cursor: pointer

      .name
        padding: 0 !important
        margin-left: -3px !important
</style>
