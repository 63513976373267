import ModelProto from '@/models/ModelProto'
import { v4 as uuid } from 'uuid'

export default class Project extends ModelProto {
  static type = 'project'
  static title = 'project'
  static plural = 'projects'
  static icon = 'mdi-office-building'

  static fields () {
    return {
      _id: uuid(),
      name: '',
      // project_id: '',
      isDefault: null
      // client_id: null
    }
  }

  static async getListSchema () {
    return [
      {
        value: 'value.name',
        text: 'project',
        align: 'start',
        sortable: false
      }
      // {
      //   value: 'value.project_id',
      //   text: 'project_id',
      //   align: 'start',
      //   sortable: true
      // }
      // {
      //   value: 'doc.name',
      //   text: 'client',
      //   align: 'start',
      //   sortable: true
      // }
    ]
  }

  static async getformSchema () {
    // const clientList = await this.getClientList($pouch)
    return {
      name: {
        type: 'text',
        label: 'name',
        rules: ['required'],
        outlined: true
      }
      // project_id: {
      //   type: 'text',
      //   label: 'project_id',
      //   rules: ['required'],
      //   outlined: true
      // }
      // client_id: {
      //   type: 'select',
      //   label: 'client',
      //   rules: ['required'],
      //   itemText: 'name',
      //   itemValue: '_id',
      //   items: clientList,
      //   outlined: true
      // }
      // isDefault: {
      //   type: 'checkbox',
      //   label: 'isDefault',
      //   outlined: true
      // }
    }
  }

  static async getClientList () {
    const res = await this.vue.$pouch.find({ selector: { type: 'project_client' } })
    return res.docs
  }

  static async beforeDelete (item) {
    await this.vue.$pouch.query('tables/tasks_by_project_id', { key: item._id, include_docs: true }).then(async (tasks) => {
      for (const taskRecord of tasks.rows) {
        const rec = await this.vue.$pouch.get(taskRecord.id)
        await this.vue.$remove(rec)
      }
    })
  }
}
