import ModelProto from '@/models/ModelProto'
import { v4 as uuid } from 'uuid'
import { getNow } from '@/utils'

export default class ProjectTask extends ModelProto {
  static type = 'project_task'
  static title = 'task'
  static plural = 'tasks'
  static icon = 'mdi-format-list-checks'

  static fields () {
    return {
      _id: uuid(),
      project_uuid: null,
      name: '00-NewTask',
      description: '',
      // rate_hour: 75,
      progress: 'progress0',
      urgency: 0,
      taskType: '',
      priority: 0,
      sort: 1,
      createdAt: getNow().toIsoString()
    }
  }

  static async getListSchema () {
    return [
      { value: 'doc.name', text: 'project', align: 'start', sortable: true },
      { value: 'doc.project_id', text: 'project_id', align: 'start', sortable: true },

      { value: 'value.name', text: 'task', align: 'start', sortable: true },
      { value: 'value.progress', text: 'progress', align: 'start', sortable: true }
      // { value: 'value.rate_hour', text: 'rate', align: 'end', sortable: true }
    ]
  }

  static async getformSchema () {
    const projectlist = await this.getProjectList()
    return {
      name: { type: 'text', label: 'name', rules: ['required'], outlined: true },
      project_uuid: {
        type: 'select',
        label: 'project',
        itemText: 'name',
        itemValue: '_id',
        items: projectlist,
        rules: ['required'],
        outlined: true
      },
      description: { type: 'textarea', autoGrow: true, rows: 3, dense: true, label: 'description', outlined: true },
      progress: {
        type: 'btn-toggle',
        row: true,
        label: 'progress',
        itemValue: 'value',
        options: this.getProgressList(),
        rules: ['required'],
        outlined: true
      },
      taskType: {
        type: 'btn-toggle',
        row: true,
        label: 'taskType',
        itemValue: 'value',
        options: this.getTypesList(),
        outlined: true
      },
      urgency: {
        type: 'radio',
        row: true,
        // label: 'urgency',
        options: this.getUrgenciesList(),
        outlined: true
      }
      // rate_hour: { type: 'v-currency-field', label: 'rate', rules: ['required'] }

    }
  }

  static async getProjectList () {
    const res = await this.vue.$pouch.find({ selector: { type: 'project' } })
    return res.docs
  }

  static getProgressList () {
    return [
      { value: 'progress0', label: '', class: 'waiting', icon: 'mdi-lightbulb-on-outline' },
      { value: 'progress1', label: '', class: 'priority', icon: 'mdi-calendar-start' },
      { value: 'progress2', label: '', class: 'in-progress', icon: 'mdi-calendar-today' },
      { value: 'progress3', label: '', class: 'in-progress', icon: 'mdi-tools' },
      { value: 'progress4', label: '', class: 'in-progress', icon: 'mdi-thumbs-up-down-outline' },
      { value: 'progress5', label: '', class: 'in-progress', icon: 'mdi-thumb-up-outline' },
      { value: 'progress99', label: '', class: 'in-progress', icon: 'mdi-archive-outline' }
    ]
  }

  static getUrgenciesList () {
    return [
      { val: '0', activeClass: 'primary--text', class: 'primary--text', color: 'primary' },
      { val: '1', activeClass: 'success--text', class: 'success--text', color: 'success' },
      { val: '2', activeClass: 'info--text', class: 'info--text', color: 'info' },
      { val: '3', activeClass: 'warning--text', class: 'warning--text', color: 'warning' },
      { val: '4', activeClass: 'error--text', class: 'error--text', color: 'error' }
    ]
  }

  static getTypesList () {
    return [
      { value: 'type0', label: '', icon: 'mdi-information-outline', class: 'primary--text' },
      { value: 'type1', label: '', icon: 'mdi-lightbulb-on-outline', class: 'primary--text' },
      { value: 'type2', label: '', icon: 'mdi-bug-outline', class: 'primary--text' },
      { value: 'type3', label: '', icon: 'mdi-flag-outline', class: 'primary--text' },
      { value: 'type4', label: '', icon: 'mdi-comment-question-outline', class: 'primary--text' }
    ]
  }
}
