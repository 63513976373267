import ModelProto from '@/models/ModelProto'
import { v4 as uuid } from 'uuid'
import Project from './Project'

export default class ProjectClient extends ModelProto {
  static type = 'project_client';
  static title = 'client';
  static plural = 'clients';
  static icon = 'mdi-handshake';

  static fields () {
    return {
      _id: uuid(),
      name: '',
      default_rate_hour: 0.00,
      default_round_minutes: 15,
      isDefault: null
    }
  }

  static async getListSchema () {
    return [
      {
        value: 'doc.name',
        text: 'name',
        align: 'start',
        sortable: true
      }
      // {
      //   value: 'doc.default_rate_hour',
      //   text: 'rate',
      //   align: 'end',
      //   sortable: true
      // },
      // {
      //   value: 'doc.default_round_minutes',
      //   text: 'rounding',
      //   align: 'end',
      //   sortable: true
      // }
    ]
  }

  static async getformSchema () {
    return {
      name: { type: 'text', label: 'name', rules: ['required'], outlined: true }
      // default_rate_hour: { type: 'v-currency-field', label: 'rate', rules: ['required'] },
      // default_round_minutes: { type: 'number', label: 'rounding', rules: ['required'] }
      // isDefault: {
      //   type: 'checkbox',
      //   label: 'isDefault',
      //   outlined: true
      // }
    }
  }

  static async beforeDelete (item) {
    await this.vue.$pouch.query('tables/projects_by_client_id', { key: item._id }).then(async (projects) => {
      for (const project of projects.rows) {
        const rec = await this.vue.$pouch.get(project.id)
        await Project.beforeDelete(rec)
        await this.vue.$remove(rec)
      }
    })
  }
}
