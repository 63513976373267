<template lang="pug">
  v-container
    v-btn.elevation-0(
      @click="toggle"
      small
      style="margin-top: -5px"
      text
    )
      v-icon(:style="lang==='en'?'opacity: 40%':''").mr-3 mdi-table
</template>

<script>
export default {
  mounted () {
  },
  data () {
    return {
      lang: this.$i18n.locale,
      labels: { en: 'DE', de: 'EN' }
    }
  },
  watch: {
    lang (lang) {
      this.$i18n.locale = lang
    }
  },
  methods: {
    toggle () {
      const settings = this.$store.state.appSettings
      settings.denseTables = !settings.denseTables
      this.$store.commit('setAppSettings', settings)
    }
  }
}
</script>
