<template lang="pug">
  v-card.transparent.typeButton(flat)
    v-icon.float-right.text--lighten-2.icon(
      dark
      :class="iconClass"
      small)
      | {{icon}}
</template>

<script>
export default {
  name: 'type-button',
  props: {
    model: {
      defaultValue: { type: '' },
      type: Object
    },
    iconClass: {
      defaultValue: '',
      type: String
    },
    icon: {
      defaultValue: '',
      type: String
    }
  }
}
</script>

<style scoped lang="sass">
.typeButton
  position: absolute
  bottom: 6px
  right: 0
  max-width: 50px
  width: 20%
  height: 20px

  .icon
    position: absolute
    top: 5px
    right: 3px
    cursor: default

</style>
