import moment from 'moment'
import i18n from '@/i18n'

export function getNow () {
  const dt = moment()
  return dt
  // const offset = dt.utcOffset()
  // return dt.subtract(offset, 'minutes')
}

export function formatChanged (date) {
  const now = moment()
  const dt = moment(date)
  if (now.format('YYYY-MM-DD') !== dt.format('YYYY-MM-DD')) {
    return dt.format('YYYY-MM-DD')
  }
  return dt.format('HH:mm')
}

export function clone (obj) {
  return JSON.parse(JSON.stringify(obj))
}

export const validation = {
  required: v => !!v || i18n.t('messages.required_field')
}

export function shadeBlend (p, c0, c1) {
  const n = p < 0 ? p * -1 : p
  const u = Math.round
  const w = parseInt
  let f
  let t
  if (c0.length > 7) {
    f = c0.split(','); t = (c1 || (p < 0 ? 'rgb(0,0,0)' : 'rgb(255,255,255)')).split(',')
    const R = w(f[0].slice(4))
    const G = w(f[1])
    const B = w(f[2])
    return 'rgb(' + (u((w(t[0].slice(4)) - R) * n) + R) + ',' + (u((w(t[1]) - G) * n) + G) + ',' + (u((w(t[2]) - B) * n) + B) + ')'
  } else {
    f = w(c0.slice(1), 16); t = w((c1 || (p < 0 ? '#000000' : '#FFFFFF')).slice(1), 16)
    const R1 = f >> 16
    const G1 = f >> 8 & 0x00FF
    const B1 = f & 0x0000FF
    return '#' + (0x1000000 + (u(((t >> 16) - R1) * n) + R1) * 0x10000 + (u(((t >> 8 & 0x00FF) - G1) * n) + G1) * 0x100 + (u(((t & 0x0000FF) - B1) * n) + B1)).toString(16).slice(1)
  }
}
