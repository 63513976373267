<template lang="pug">
  v-layout.pa-0.ma-0( style="position:absolute; top:-15px; right:0; left:-20px;bottom:0")
    v-col.col-auto.pa-0.pt-3.ma-0.fill-height(style="border-right: 1px solid  rgba(127,127,127,0.3)")
      v-list( dense ).pa-0.ma-0
        v-list-item.rounded-0(dense :class="(currentFolder._id==='all')?'v-list-item--active':''" @click="setCurrentFolder({_id:'all'})")
          v-list-item-content All Folders
          v-icon(dense x-small @click="addFolder()")  mdi-plus
        v-list-item(dense :class="(currentFolder._id==='none')?'v-list-item--active':''" @click="setCurrentFolder({_id:'none'})") Uncategorized
        v-list-item(dense v-for="folder in folders"  :class="(folder._id===currentFolder._id)?'v-list-item--active':''" @click="setCurrentFolder(folder)")
          v-icon.pl-2(v-if="currentEditFolderId  !== folder._id"  dense  )  mdi-folder-outline
          v-icon(v-if="currentEditFolderId  === folder._id" dense size="28"  @click="delFolder")  mdi-delete-outline
          div(style="margin-left:10px" v-if="currentEditFolderId !== folder._id" @dblclick="currentEditFolderId=folder._id") {{folder.name}}
          v-text-field(style="margin-left:10px"  v-if="currentEditFolderId  === folder._id"  v-model="folder.name"  @change="updateFolder")
    v-col.col-auto.pa-0.pt-3.ma-0(style="border-right: 1px solid rgba(127,127,127,0.3)")
      v-list( dense ).pa-0.ma-0
        v-list-item.pa-0.ma-0(dense)
          v-list-item-content.pl-3
            v-text-field.knowledge.pa-0.ma-0(type='text' dense placeholder="Search" prepend-icon="mdi-magnify" clearable)
          v-btn.rounded-0(text dense @click="addItem" style="position-relative; top:-8px").pa-0.ma-0
            v-icon.pa-0.ma-0(dense x-small  ) mdi-plus
        v-divider.pa-0.ma-0( dense)
        v-list-item(
          dense v-for="item in items"
          :class="(item._id===currentItem._id)?'v-list-item--active':''"
          @click="setCurrentItem(item)"
          @contextmenu.prevent="onContext($event, item)"
        )
          v-list-item-content(style="white-space:nowrap; font-size:90%") {{item.name!=='' ? item.name : 'untitled snippet'}}
          //v-spacer
          v-list-item-action(style="font-size: 70%; float:right; text-align:right") {{formatChanged(item.updated)}}
    v-col.col-auto.ma-0.pa-0.pt-3.flex-fill.form(v-if="isEditing()")
      v-text-field.name.knowledge.search.rounded-0.m0(type='text' @change="updateItem" placeholder="untitled snippet" dense  v-model="currentItem.name" )
      v-combobox.knowledge(label="tags:"  clearable    hide-selected    @change="updateItem"    multiple  v-model="currentItem.tags" :items="tags" )
      v-textarea.knowledge(label="notes:" @change="updateItem" dense  v-model="currentItem.notes"   rows="1" auto-grow)
      codemirror.rounded-0.ma-0( placeholder="content" :options="getOptions()" dense  v-model="currentItem.text"  auto-grow)

      v-select.knowledge(label="mode"  dense  @change="updateItem"  v-model="currentItem.mode" :items="modes" item-value="val" item-text="name" )
      v-select.knowledge(label="folder"  dense  @change="updateItem"  v-model="currentItem.folder" :items="getFolderList()" item-value="_id" item-text="name" )
      v-btn.knowledge.text-capitalize(placeholder="Text"   @click="deleteItem()"   text)
        v-icon(dense small) mdi-delete-outline
        | delete

</template>

<script>
import { codemirror } from 'vue-codemirror'
import { getNow, formatChanged } from '@/utils'
import 'codemirror/lib/codemirror.css'
import 'codemirror/mode/javascript/javascript.js'
import 'codemirror/mode/php/php.js'
import 'codemirror/mode/markdown/markdown.js'
import 'codemirror/mode/css/css.js'
import 'codemirror/mode/shell/shell.js'
import 'codemirror/mode/yaml/yaml.js'
import 'codemirror/mode/xml/xml.js'
import 'codemirror/mode/sql/sql.js'
import 'codemirror/theme/darcula.css'

export default {
  components: { codemirror },
  name: 'Knowledgebase',
  mounted () {
    this.$syncDb()
    this.$pouch.get('knowledge_tags').then((res) => {
      this.tags = res.tags
    })
    this.getKnowledgeItems()
    this.getKnowledgeFolders()
  },
  beforeRouteLeave (to, from, next) {
    this.updateItem()
    next()
  },
  data () {
    return {
      currentItem: { type: '' },
      currentFolder: { _id: 'all' },
      currentEditFolderId: null,
      items: [],
      folders: [],
      tags: [],
      cmOptions: {
        tabSize: 4,
        lineWrapping: true,
        mode: 'text',
        theme: 'darcula',
        lineNumbers: true,
        autofocus: true
        // more CodeMirror options...
      },
      modes: [
        {
          val: 'text',
          name: 'text'
        },
        {
          val: 'text/x-sh',
          name: 'bash'
        },
        {
          val: 'css',
          name: 'css'
        },
        {
          val: 'javascript',
          name: 'javascript'
        },
        {
          val: 'text/markdown',
          name: 'markdown'
        },
        {
          val: 'text/x-php',
          name: 'php'
        },
        {
          val: 'text/syl',
          name: 'sql'
        },
        {
          val: 'text/xml',
          name: 'xml'
        },
        {
          val: 'text/yaml',
          name: 'yaml'
        }
      ]

    }
  },

  methods: {
    getOptions () {
      const options = this.cmOptions
      options.mode = this.currentItem.mode || 'text'
      // options.lineNumbers = (options.mode !== 'text') && (options.mode !== 'text/markdown')
      return options
    },
    onContext (ev) {
      // const menu = this.$refs.contextmenu
      // menu.$props
      //   .console.log(ev.x)
      // menu.openMenuItems()
    },
    getContextItems () {
      const modes = this.modes.map((item) => {
        return {
          label: item.name,
          action: ''
        }
      })
      const folders = this.folders.map((item) => {
        return {
          label: item.name,
          action: ''
        }
      })
      return [
        {
          name: 'move',
          menu: folders
        },
        {
          name: 'syntax',
          menu: modes

        },
        {
          name: 'delete'
          // action: this.remove
        }
      ]
    },

    isEditing () {
      if (this.currentItem !== undefined) {
        if (Object.prototype.hasOwnProperty.call(this.currentItem, 'type')) {
          return this.currentItem.type === 'knowledge_item'
        }
      }
      return false
    },
    formatChanged (date) {
      return formatChanged(date)
    },
    async setCurrentItem (item) {
      this.$syncDb()
      this.currentItem = item
    },
    async setCurrentFolder (folder) {
      if (folder._id !== this.currentEditFolderId) {
        this.currentEditFolderId = null
        await this.updateItem(this.currentItem)
        if ((this.currentItem.folder !== folder._id) && (folder._id !== 'all')) {
          this.currentItem = { type: '' }
        }
        this.currentFolder = folder
        await this.getKnowledgeItems()
      }
    },
    async updateTags (tags = []) {
      const merged = this.tags.concat(tags.filter((item) => this.tags.indexOf(item) < 0))
      let item = await this.$pouch.get('knowledge_tags').catch(() => {
        item = {
          type: 'knowledge_tags',
          _id: 'knowledge_tags'
        }
      })

      item.tags = merged

      await this.$upsert(item)
      this.$pouch.get('knowledge_tags').then((res) => {
        this.tags = res.tags
      })
    },
    async getKnowledgeItems () {
      await this.$pouch.createIndex({
        index: { fields: ['name'] }
      })
      const items = await this.$pouch.find({
        selector: {
          name: { $gte: null },
          type: 'knowledge_item'
        },
        sort: ['name']
      })
      if (this.currentFolder._id === 'all') {
        this.items = items.docs
      } else if (this.currentFolder._id === 'none') {
        const folders = this.folders.map(f => f._id)
        this.items = items.docs.filter((item) => {
          return (folders.indexOf(item.folder) < 0)
        })
      } else {
        this.items = items.docs.filter((item) => {
          return item.folder === this.currentFolder._id
        })
      }
    },
    async getKnowledgeFolders () {
      await this.$pouch.createIndex({
        index: { fields: ['name'] }
      })
      const folders = (await this.$pouch.find({
        selector: {
          name: { $gte: null },
          type: 'knowledge_folder'
        },
        sort: ['name']
      }))
      this.folders = folders.docs
    },
    getFolderList () {
      const list = [...this.folders]
      list.unshift({
        _id: '',
        name: 'none'
      })
      return list
    },
    async addFolder () {
      const item = {
        type: 'knowledge_folder',
        name: 'untitled folder'
      }
      await this.$pouch.post(item).then(async (rec) => {
        await this.getKnowledgeFolders()
        this.currentFolder = this.folders[0]
      })
    },
    async delFolder () {
      await this.$remove(this.currentFolder)
      this.currentFolder = { _id: 'all' }
      await this.getKnowledgeFolders()
    },
    async addItem () {
      let folder = ''
      if ((this.currentFolder._id !== 'all') && (this.currentFolder._id !== 'none')) {
        folder = this.currentFolder._id
      }
      const item = {
        type: 'knowledge_item',
        name: '',
        text: '',
        mode: 'text',
        folder: folder,
        updated: getNow().format('YYYY-MM-DD HH:mm:ss')
      }
      await this.$pouch.post(item).then(async (rec) => {
        await this.getKnowledgeItems()
        this.currentItem = this.items[0]
      })
    },
    async updateItem () {
      if (this.currentItem !== undefined) {
        if (Object.prototype.hasOwnProperty.call(this.currentItem, 'type')) {
          if (this.currentItem.type === 'knowledge_item') {
            const original = await this.$pouch.get(this.currentItem._id)
            if (JSON.stringify(original) !== JSON.stringify(this.currentItem)) {
              await this.updateTags(this.currentItem.tags)
              this.currentItem.updated = getNow().format('YYYY-MM-DD HH:mm:ss')
              await this.$upsert(this.currentItem)
              await this.getKnowledgeItems()
              const item = await this.$pouch.get(this.currentItem._id)
              this.currentItem = item
              if (item.folder !== this.currentFolder._id) {
                if (this.currentFolder._id !== 'all') {
                  await this.setCurrentFolder({ _id: item.folder || 'all' })
                }
              }
              this.$syncDb()
            }
          }
        }
      }
    },
    async updateFolder () {
      if (this.currentFolder.type === 'knowledge_folder') {
        await this.$upsert(this.currentFolder)
        await this.getKnowledgeFolders()
        this.currentFolder = await this.$pouch.get(this.currentFolder._id)
        this.$syncDb()
      }
    },
    async deleteItem () {
      const res = await this.$dialog.confirm({
        title: this.$t('kanban.Really delete?'),
        text: '',
        actions: {
          false: this.$t('kanban.cancel'),
          true: this.$t('kanban.delete')
        }
      })
      if (res) {
        await this.$remove(this.currentItem)
        await this.getKnowledgeItems()
        this.currentItem = { type: '' }
        this.$syncDb()
      }
    }
  }

}
</script>

<style lang="scss">
.CodeMirror-wrap pre {
  word-break: break-word;
}
.v-input.name .v-text-field__details{
  display: none !important;
}
.knowledge {
  .v-autocomplete{
    padding: 0 !important;
  }

  font-size:  90%;
  .v-input__slot {
    border-bottom: solid 1px  rgba(127,127,127,0.2);
    &::before, &::after {
      border: none !important;
    }
  }
  label {
    margin-left: 20px;
    &.v-label--active{
      display: none;
    }
  }
  input{

    text-indent: 10px;
    &:focus{
      background-color: rgba(127,127,127,0.2);
    }
  }
  textarea:focus{
    background-color: rgba(127,127,127,0.2);
  }
}
.form .knowledge {
  input{
    text-indent: 20px;
  }
  textarea, select{
    padding-left: 20px;
  }
  .v-select__selection{
    padding-left: 20px;
  }

  margin: 0;
}

.cm-s-darcula {
  background-color: rgba(0, 0, 0, 0.1) !important;;
  //border-radius: 5px;
  padding: 10px 10px 10px 0;
  height: auto;
  min-height: 50px;
  margin-bottom: 25px;
  border-top: 1px solid rgba(127,127,127,0.3);
  border-bottom: 1px solid rgba(127,127,127,0.3);
  line-height: 16px;

  .CodeMirror-gutters {
    background-color: transparent;
    border-right: 1px solid rgba(127,127,127,0.3);
    line-height: 16px;

  }
}
</style>
