<template lang="pug">
  v-card(flat)
    portal(to="pagetitle")
      v-container.text-capitalize(style="white-space: nowrap")
        | {{$t('left_menu.admin')}}
    nav
      v-card(flat :style="isMobile? 'padding: 20px 0 100px 0' :''").pb-16
        v-list( nav )
          v-list-item
            v-switch(
              color="green"
              v-model="syncingActive"
              :label="syncingActiveLable"
              flat)
          v-list-item()
            v-btn(@click="aggregateEvents" outlined style="text-transform: none"    color="success" )  (re-) Aggreegate Events
          v-list-item()
            v-btn(@click="putDdocs" outlined style="text-transform: none"    color="success" )  Init Couchdb Views
          v-list-item()
            v-btn(@click="buildInitialStream" outlined style="text-transform: none"    color="error" )  Build Stream
          v-list-item()
            v-btn(@click="clearAggrDb" outlined style="text-transform: none"    color="error" )   Aggregates DB Delete
          v-list-item()
            v-btn(@click="clearEventStreamDb" outlined style="text-transform: none"    color="error" )   Event Stream DB Delete
          //v-list-item()
          //
          //    v-btn(@click="test" outlined style="text-transform: none"    color="error") test

            //v-btn(@click="loadDefaults" text style="text-transform: none"    color="error" ) {{$t('settings.load_defaults')}}
</template>

<script>
import medsChooser from './MedsChooser'
import globalSettings from './GlobalClientSettings'
import axios from 'axios'
import EventItem from '../models/EventItem'
import ModelProto from '../models/ModelProto'

export default {
  name: 'AdminSettings',
  components: { medsChooser, globalSettings },
  mounted () {
    this.syncingActive = this.$store.state.syncingActive
  },

  computed: {
    syncingActiveLable () {
      return this.syncingActive ? 'syncing is active' : 'syncing is NOT active'
    },
    settings () {
      return this.$store.state.appSettings
    },
    isFuo () {
      return (this.$store.state.currentUser === 'fuo') || (this.$store.state.currentUser === 'jmfuo')
    },
    isMobile () {
      const val = this.$vuetify.breakpoint.width < 769
      this.$store.commit('setIsMobile', val)
      return val
    },
    syncingActive: {
      get () {
        return this.$store.state.syncingActive
      },
      set (valuePassedThrough) { // the value is passed through the v-model automatically
        this.$store.commit('setSyncingActive', valuePassedThrough)
        this.$store.commit('setSyncstate', valuePassedThrough ? 'current' : 'offline')
      }
    }
  },
  methods: {
    test () {
      console.log(this.$store.state.currentUser)
    },
    toggleSyncing () {
    },
    async clearMem () {
      const res = await this.$dialog.error({
        title: this.$t('settings.really_reset_app'),
        text: this.$t('settings.really_reset_app_text'),
        actions: {
          false: this.$t('settings.cancel'),
          true: this.$t('settings.reset_app')
        }
      })
      if (res) {
        await this.$store.commit('setCurrentUser', '')
        await this.$store.commit('setLastUser', '')
        await this.$store.commit('setSyncstate', 'offline')
        await axios.get(process.env.VUE_APP_AUTH_URL + '/logout',
          { withCredentials: true })
        await window.indexedDB.databases().then((r) => {
          for (let i = 0; i < r.length; i++) window.indexedDB.deleteDatabase(r[i].name)
        })
        localStorage.clear()
        location.reload()
      }
    },
    async clearAggrDb () {
      const res = await this.$dialog.error({
        title: ' ',
        text: 'Really Delete Aggreagate Database?',
        actions: {
          false: this.$t('settings.cancel'),
          true: 'Delete Aggreagate Database'
        }
      })
      if (res) {
        this.$clearAggrDb()
        location.reload()
      }
    },

    async clearEventStreamDb () {
      const res = await this.$dialog.error({
        title: ' ',
        text: 'Really Delete Event Stream Database?',
        actions: {
          false: this.$t('settings.cancel'),
          true: 'Delete Event Stream Database'
        }
      })
      if (res) {
        this.$clearEventStreamDb()
        location.reload()
      }
    },

    async handleExport () {
      await this.$syncDb()
      await this.$pouch.allDocs({ include_docs: true }, (error, doc) => {
        if (error) {
          console.error(error)
        } else {
          let filename = 'projects_backup.json'
          if (this.$store.state.lastUser) {
            filename = 'projects_backup_' + this.$store.state.lastUser + '.json'
          }
          if (this.$store.state.currentUser) {
            filename = 'projects_backup_' + this.$store.state.currentUser + '.json'
          }
          this.download(
            JSON.stringify(doc.rows.map(({ doc }) => doc)),
            filename,
            'text/plain'
          )
        }
      })
    },
    download (data, filename, type) {
      const file = new Blob([data], { type: type })
      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(file, filename)
      } else { // Others
        const a = document.createElement('a')
        const url = URL.createObjectURL(file)
        a.href = url
        a.download = filename
        document.body.appendChild(a)
        a.click()
        setTimeout(function () {
          document.body.removeChild(a)
          window.URL.revokeObjectURL(url)
        }, 0)
      }
    },
    async buildInitialStream (data, filename, type) {
      const res = await this.$dialog.error({
        title: ' ',
        text: 'Really Rebuild Event Stream Database?',
        actions: {
          false: this.$t('settings.cancel'),
          true: 'Rebuild Event Stream Database'
        }
      })
      if (res) {
        // console.log(await this.$eventStream.allDocs({ include_docs: true }))
        await this.$pouch.allDocs({ include_docs: true }, (error, doc) => {
          if (error) {
            console.error(error)
          } else {
            doc.rows.forEach(row => {
              if (!row.doc._deleted) {
                this.$saveUpsertEvent(row.doc, {})
              }
            })
          }
        })
      }
    },
    async aggregateEvents () {
      await this.$aggregateEvents()
    },
    putDdocs () {
      // console.log(await this.$pouch.get('_design/kanban'))
      ModelProto.putDddocs()
    }

  }
}
</script>

<style scoped>

</style>
