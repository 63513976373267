
export default {
  install (Vue, store, options = {}) {
    Vue.prototype.$getUserSettings = async () => {
      return this.$pouch.get('userSettings').catch((err) => {
        if (err.name === 'not_found') {
          return {
            _id: 'userSettings'
          }
        } else { // hm, some other error
          throw err
        }
      }).then((configDoc) => {
        return configDoc
      })
    }
    Vue.prototype.$updatetUserSettings = async () => {

    }
  }
}
