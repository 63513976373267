<template lang="pug">
  div()
    v-toolbar.nav.transparent.hidden-sm-and-down(flat dense)
      v-btn( dense small  text to='/kanban/prio')  {{$t('kanban.prio')}}
      v-btn( dense small v-for='(route, id) in routes' :key='id' text :to='route.dest')
          |   {{ route.name }}
    portal(to="pagetitle" @mousedown.native.stop)
      v-toolbar-items
        v-dialog(content-class="dialogTop"  dense v-model="dialog"  transition="dialog-top-transition" )
          template(v-slot:activator='{ on, attrs }')
            v-container.text-no-wrap.text-capitalize.toolbar.nav.text-capitalize(style="cursor: pointer"  text v-bind='attrs' v-on='on')
              span {{title}}
              v-icon mdi-menu-down
          v-toolbar(color="primarySpecial" ).text-no-wrap.justify-end.white--text
              v-card.transparent(flat style="margin-right: auto" ).justify-start.white--text  {{$t('kanban.task_boards')}}
              v-btn( text  to='/project-kanboard/edit/new').white--text
                v-icon mdi-plus
              v-btn( text  @click="dialog=false").white--text
                v-icon mdi-close
          v-list
            v-list-item(dense class="kanban-dropdown"  to='/kanban/prio')
              v-list-item-icon
                v-icon(small) mdi-square
              v-list-item-content( )
                v-list-item-title Prio
            draggable(v-model="boards" class="div"  animation='300' handle=".handle" @end="dragend" group='projects' )
              v-list-item(dense class="kanban-dropdown"  v-for="(route, id) in routes" :key='id' :to='route.dest')
                v-list-item-icon
                  v-icon.handle(small)  mdi-arrow-all
                v-list-item-content( )
                  v-list-item-title {{ route.name }}
                v-list-item-icon.mt-0
                  v-btn(dense fab  text small   :to='getSingleEditRout(route)')
                    v-icon mdi-pencil

</template>

<script>
import draggable from 'vuedraggable'

export default {
  name: 'kanban-menu',
  components: {
    draggable
  },
  mounted () {
    this.getBoardRoutes()
  },
  computed: {
    editRoute () {
      return '/project-kanboard/edit/' + this.$route.params.id
    }
  },
  props: {
    title: String
  },
  data () {
    return {
      routes: [],
      boards: [],
      dialog: false
    }
  },
  methods: {
    getSingleEditRout (item) {
      return '/project-kanboard/edit/' + item.dest.params.id
    },
    async getBoardRoutes () {
      // const prio = [{ name: this.$t('kanban.All'), dest: { name: 'kanban', params: { id: 'prio' }, canReuse: false } }]
      const kanbans = await this.$pouch.query('kanban/kanboardssorted', { include_docs: true })
      this.routes = kanbans.rows.map((x) => {
        return { name: x.doc.name, dest: { name: 'kanban', params: { id: x.id }, canReuse: false } }
      })
      this.boards = kanbans.rows.map(y => y.doc)
    },
    dragend () {
      this.boards.forEach((b, i) => {
        this.boards[i].sort = i
      })
      this.boards.forEach(async board => {
        await this.$upsert(board)
      })
      this.getBoardRoutes()
    }
  }
}
</script>

<style scoped>
>>> .dialogTop {
  position:absolute;
  /*left:-25px;*/
  top:0;
  max-width: 500px;
  margin-top: 0;
}
</style>
