<template lang="pug">
  v-card.prioButton.transparent.toggleCursor(flat
  @mouseup.stop='mouseupHandler(model)')
    v-icon.float-right.orange--text.text--lighten-2.toggleCursor.icon(
      dark v-if='(model.priority > 0)'
      x-small)
      | mdi-checkbox-blank-circle
    v-icon.float-right.toggleCursor.icon(
      dark v-if='(model.priority < 1)'
      style='opacity:0.3;'
      x-small)
      | mdi-checkbox-blank-circle-outline

</template>

<script>
export default {
  name: 'prio-button',
  props: {
    model: {
      defaultValue: { prio: 0 },
      type: Object
    },
    mouseupHandler: {
      defaultValue: (model) => {
      },
      type: Function
    }
  }
}
</script>

<style scoped lang="sass">
.prioButton
  position: absolute
  right: 0
  top: 0
  max-width: 50px
  width: 20%
  height: 20px
  .icon
    position: absolute
    top: 5px
    right: 5px

</style>
