import PouchFind from 'pouchdb-find'
import PouchDBAuthentication from 'pouchdb-authentication'
import PouchDB from 'pouchdb-browser'
import { applyDiff, getDiff } from 'recursive-diff'
import { getNow } from '../utils'
import { v4 as uuid } from 'uuid'

PouchDB.plugin(PouchFind)
// PouchDB.plugin(PouchLiveFind)
PouchDB.plugin(PouchDBAuthentication)

PouchDB.plugin(require('pouchdb-upsert'))
function toHex (str) {
  let result = ''
  for (let i = 0; i < str.length; i++) {
    result += str.charCodeAt(i).toString(16)
  }
  return result
}

export default {
  install (Vue, store, options = {}) {
    let currentUser = store.state.currentUser.length ? store.state.currentUser : store.state.lastUser
    currentUser = currentUser.length ? currentUser : 'anon'
    const aggrDbName = currentUser + '_glyph_projects_aggregates'
    const eventsDBName = currentUser + '_glyph_projects_event_stream'
    const aggrDb = new PouchDB(aggrDbName, { auto_compaction: true })
    const eventsDB = new PouchDB(eventsDBName, { auto_compaction: true })

    const defaulOptions = {
      onSyncChange: (data) => {
      },
      onSyncComplete: () => {
      },
      onSyncPaused: () => {
      },
      onSyncError: () => {
      }
    }

    options = Object.assign(defaulOptions, options)
    Vue.prototype.$eventStream = eventsDB

    Vue.prototype.$pouch = aggrDb
    Vue.prototype.$syncDb = async (onComplete = () => {
    }, onGetError) => {
      if (!store.state.syncingActive) {
        await Vue.prototype.$refreshToken()
        return
      }
      onGetError = onGetError || onComplete

      if (!navigator.onLine) {
        store.commit('setSyncstate', 'offline')
        return onComplete()
      }

      await Vue.prototype.$refreshToken()

      // use only local aggrDb if offline
      if (store.state.currentUser === '') {
        return onComplete()
      }

      const url = process.env.VUE_APP_REMOTE_DATABASE_URL + '/userdb-' + toHex(store.state.currentUser)
      const dbserver = new PouchDB(url, {
        fetch: function (url, opts) {
          opts.headers.set('Authorization', 'Bearer ' + store.state.token)
          return PouchDB.fetch(url, opts)
        },
        skip_setup: true
      })
      const opts = { /* live: true, */
        retry: true
      }
      store.commit('setSyncstate', 'syncing')
      eventsDB.replicate.from(dbserver).on('complete', (info) => {
        onComplete()
        store.commit('setSyncstate', 'current')

        eventsDB.sync(dbserver, opts)
          .on('change', (data) => { updateAggregates(data.docs); options.onSyncChange(data) })
          .on('paused', options.onSyncPaused)
          .on('complete', options.onSyncComplete)
          .on('error', () => {
            console.log('ERROR!!!')
            store.commit('setSyncstate', 'offline')
            options.onSyncError()
          })
      }).on('change', (data) => {
        updateAggregates(data.docs)
        options.onSyncChange(data)
        store.commit('setSyncstate', 'current')
      })
        .on('error', (er) => {
          onGetError()
        })
    }
    const updateAggregates = (arr = []) => {
      if (Array.isArray(arr)) {
        const uniqueItems = new Set()
        arr.forEach(item => {
          uniqueItems.add(item.aggrId)
        })
        uniqueItems.forEach((id) => {
          if (id) {
            Vue.prototype.$upsertAggregateSnapshot(id)
          }
        })
      }
    }
    Vue.prototype.$upsertAggregateSnapshot = async (recordId, rebuild = false) => {
      const aggrData = await getAggregatedData(recordId)
      let doc = {}
      const res = await aggrDb.get(recordId).then((res) => {
        doc = res
      }).catch(() => {
      })
      for (const [key, value] of Object.entries(aggrData.data)) {
        doc[key] = value
      }
      doc._id = recordId
      doc.updateInfo = aggrData.updateInfo
      if (rebuild && doc._deleted) {
        return
      }
      await aggrDb.put(doc)
    }

    const getAggregatedData = async (aggrId) => {
      let data = {}
      await eventsDB.allDocs({
        index: {
          fields: ['datetime', 'aggrId']
        }
      })
      const result = await eventsDB.find({
        selector: { datetime: { $gt: null }, aggrId: aggrId }
      })
      if (result.error) {
        return data
      }
      const updateInfo = { }

      result.docs.sort(function (a, b) {
        return new Date(a.datetime) - new Date(b.datetime)
      })
      result.docs.forEach(row => {
        updateInfo.updatedAt = row.datetime
        updateInfo.updatedBy = row.author
        updateInfo.eventId = row._id
        data = applyDiff(data, row.data)
      })
      return { data: data, updateInfo: updateInfo }
    }

    Vue.prototype.$saveUpsertEvent = async (record, aggrData = {}, isNew = false) => {
      const action = record._deleted ? 'delete' : 'upsert'
      const id = record._id
      const type = record.type
      delete record._rev
      delete record._id
      delete record.update
      delete record._conflicts // we don't want conflicts to get diffed
      delete record.updateInfo // we don't want the updateInfo to get diffed
      const diff = getDiff(aggrData, record)
      if ((diff.length > 0) || isNew) {
        const event = {}
        event._id = uuid()
        event.name = record.name ?? id
        event.aggrId = id
        event.type = type
        event.eventKey = action
        event.data = diff
        event.author = currentUser
        event.datetime = getNow().toISOString()
        await eventsDB.put(event)
      }
    }

    Vue.prototype.$aggregateEvents = async () => {
      const alldocs = await eventsDB.allDocs({ include_docs: true })
      // const aggItems = []
      const uniqueItems = new Set()
      alldocs.rows.forEach(item => {
        uniqueItems.add(item.doc.aggrId)
      })
      uniqueItems.forEach((id) => {
        if (id) {
          Vue.prototype.$upsertAggregateSnapshot(id, true)
        }
      })
    }

    Vue.prototype.$remove = async record => {
      record._deleted = true
      await Vue.prototype.$upsert(record)
    }

    Vue.prototype.$upsert = async record => {
      const id = record._id
      let aggrData = { }
      if (!(Object.prototype.hasOwnProperty.call(record, 'isNew') && record.isNew)) {
        aggrData = await getAggregatedData(id)
        aggrData = aggrData.data
      } else {
        delete record.isNew
      }
      await Vue.prototype.$saveUpsertEvent(record, aggrData)
      await Vue.prototype.$upsertAggregateSnapshot(id)
    }

    Vue.prototype.$clearAggrDb = async () => {
      window.indexedDB.deleteDatabase('_pouch_' + aggrDbName)
    }

    Vue.prototype.$clearEventStreamDb = async () => {
      window.indexedDB.deleteDatabase('_pouch_' + eventsDBName)
    }

    Vue.prototype.$syncDb()
    // Vue.prototype.$aggregateEvents()
  }

}
