<template lang="pug">
  v-container
    v-btn.elevation-0(
      @click="toggle"
      small
      style="margin-top: -5px"
      text
    )
      span(:style="lang==='en'?'opacity: 40%':''").mr-3 DE
      span(:style="lang==='de'?'opacity: 40%':''") EN
</template>

<script>
export default {
  mounted () {
  },
  data () {
    return {
      lang: this.$i18n.locale,
      labels: { en: 'DE', de: 'EN' }
    }
  },
  watch: {
    lang (lang) {
      this.$i18n.locale = lang
    }
  },
  methods: {
    toggle () {
      this.lang === 'de' ? (this.lang = 'en') : (this.lang = 'de')
      this.$store.commit('setLastLocale', this.lang)
    }
  }
}
</script>
