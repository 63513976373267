<template lang="pug">
  v-card.abstract-table.ma-1.transparent(flat).pb-16
    portal(to="pagetitle")
      v-container.text-capitalize(style="white-space: nowrap")
        | {{$t('left_menu.history')}}
    v-data-table(
      :headers="headers"
      :footer-props="{'items-per-page-options': [25,50,75,100, -1] }"
      :items-per-page='25'
      :items="items"
      :search="search"
      @click:row="handleClick"
      dense
    ).history-table
      template(v-slot:top='{ pagination, options, updateOptions }')
        div.row
          v-card.pa-0(flat  ).transparent.mx-5.row
              v-text-field(
                v-model="search"
                label="Search"
                class="")
          v-card.pa-0(flat  ).transparent.mb-2.mt-0
            v-data-footer(:pagination='pagination' :items-per-page-options="[25,50,75,100,-1]"  :options='options' @update:options='updateOptions' items-per-page-text='$vuetify.dataTable.itemsPerPageText').transparent

      template(v-slot:item.changes="{ item, index }")
        event-history-data(:doc="item.changes" :ref="'toggles'+index")
</template>

<script>
import moment from 'moment'
import EventHistoryData from './EventHistoryData'
import EventHistoryTitle from './EventHistoryTitle'

export default {
  name: 'EventHistory',
  components: { EventHistoryTitle, EventHistoryData },
  data () {
    return {
      headers: [
        { text: 'date', value: 'datetime', sortable: false, align: 'start' },
        { text: 'title', value: 'aggrId', sortable: false, align: ' d-none' },
        { text: 'title', value: 'title', sortable: false, align: 'start' },
        { text: 'action', value: 'eventKey', sortable: false, align: 'start' },
        { text: 'type', value: 'type', sortable: false, align: 'start' },
        { text: 'author', value: 'author', sortable: false, align: 'start' },
        { text: '#', value: 'len', sortable: false, align: 'end' },
        { text: 'changes', value: 'changes', sortable: false, align: 'start' }
      ],
      items: [],
      names: {},
      search: ''
    }
  },
  async mounted () {
    await this.getItems()
  },
  methods: {
    handleClick (item, row) {
      const comp = this.$refs['toggles' + row.index]
      comp.toggle()
    },
    async getItems () {
      const alldocs = await this.$eventStream.allDocs({ include_docs: true })
      const rows = alldocs.rows.sort((a, b) => {
        const dateA = new Date(a.doc.datetime)
        const dateB = new Date(b.doc.datetime)
        return dateB - dateA
      })
      this.items = await Promise.all(rows.filter(b => Object.prototype.hasOwnProperty.call(b.doc, 'type')).map((row) => {
        return {
          _id: row.doc._id,
          datetime: moment(row.doc.datetime).format('YYYY-MM-DD HH.mm.SSS'),
          aggrId: row.doc.aggrId,
          title: row.doc.name ?? row.doc.aggrId,
          eventKey: row.doc.eventKey,
          author: row.doc.author,
          type: row.doc.type,
          changes: row.doc,
          len: row.doc.data.length
        }
      }))
    }

  }
}
</script>

<style lang="sass">
.history-table table thead tr th
  padding-right: 2px!important
.history-table table tbody tr td
  vertical-align: top
  padding: 0

  &:first-of-type
    white-space: nowrap

.history-table table > tbody > tr > td
  padding: 4px 2px 4px 16px !important
  &.small-text
    font-size: 80%
</style>
