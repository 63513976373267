<template lang="pug">
  v-overlay(v-model="contextMenu")
    v-menu.pa-0(
      v-model="contextMenu"
      :position-x="x"
      :position-y="y"
      absolute
      offset-y
      :min-width="$store.state.isMobile?'100%':undefined"
      rounded="0"
    )
      v-list(dense)
        v-list-item(v-for="item in items" :key="item.action" @click="selected($event, item)")
            v-icon(:class="item.class" small) {{item.icon}}
</template>

<script>
export default {
  name: 'context-menu',
  data () {
    return {
      contextMenu: false,
      contextData: false,
      dataItem: {},
      x: 0,
      y: 0
    }
  },
  props: {
    items: {
      type: Array
    }
  },
  methods: {
    onContext (e, item) {
      this.contextData = item
      e.preventDefault()
      this.contextMenu = false
      this.dataItem = item
      this.x = e.clientX
      this.y = e.clientY
      this.$nextTick(() => {
        this.contextMenu = true
      })
    },
    selected (e, item) {
      this.$emit('contextSelect', e, item, this.contextData)
    }
  }
}
</script>

<style scoped>

</style>
