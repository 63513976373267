import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import VuexReset from '@ianwalter/vuex-reset'

Vue.use(Vuex)

const store = new Vuex.Store({
  namespaced: true,
  state: {
    syncingActive: true,
    syncstate: 'offline',
    lastLocale: 'en',
    themeDark: undefined,
    isMobile: false,
    token: '',
    refreshToken: '',
    currentUser: '',
    lastUser: '',
    isMobileLandscape: false,
    portalName: 'pagetools',
    appSettings: {
      clientToken: '',
      taskTitleSize: 100,
      taskTitleSizeLandscape: 100,
      titlesOnly: false,
      denseTables: false,
      showInfo: true,
      progressVisible: [
        'progress1',
        'progress2',
        'progress3',
        'progress5'],
      typesVisible: [],
      urgenciesVisible: []
    }
  },
  getters: {
    isLoggedIn (state) {
      return state.currentUser !== ''
    }
  },
  mutations: {
    setSyncingActive (state, syncingActive) {
      state.syncingActive = syncingActive
    },
    setLastLocale (state, locale) {
      state.lastLocale = locale
    },
    setSyncstate (state, syncstate) {
      state.syncstate = syncstate
    },
    setThemeDark (state, themeDark) {
      state.themeDark = themeDark
    },
    setAppSettings (state, appSettings) {
      state.appSettings = appSettings
    },
    setCurrentUser (state, currentUser) {
      state.currentUser = currentUser
    },
    setLastUser (state, lastUser) {
      state.lastUser = lastUser
    },
    setToken (state, token) {
      state.token = token
    },
    setRefreshToken (state, refreshToken) {
      state.refreshToken = refreshToken
    },
    setIsMobile (state, isMobile) {
      state.isMobile = isMobile
    },
    setPortalName (state, portalName) {
      state.portalName = portalName
    },
    setIsMobileLandscape (state, isMobileLandscape) {
      state.isMobileLandscape = isMobileLandscape
    }
  },
  plugins: [VuexReset(), createPersistedState(
    {
      paths: ['lastLocale', 'appSettings', 'themeDark', 'token', 'refreshToken', 'lastUser', 'syncingActive']
    }
  )]
})
export default store
