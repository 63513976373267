<template lang="pug">

</template>

<script>
import axios from 'axios'

export default {
  beforeMount () {
    this.logout()
  },
  data () {
    return {
      username: 'admin',
      password: 'test'
    }
  },
  computed: {
    settings () {
      return this.$store.state.appSettings
    }
  },
  methods: {
    async logout () {
      const data = {
        refresh_token: this.$store.state.refreshToken
      }
      await this.$store.commit('setSyncstate', 'offline')
      await axios.get(process.env.VUE_APP_AUTH_URL + '/logout',
        { withCredentials: true })
      await this.$store.commit('setCurrentUser', '')
      await this.$store.commit('setToken', '')
      await this.$store.commit('setRefreshToken', '')

      location.reload()
    }
  }
}
</script>
