<template lang="pug">
  v-dialog(v-model="dialog"  max-width="500" @click:outside="cancel").kanban
    v-card.pt-6(:class="'kanban-item urgency-'+editrecord.urgency")
      v-row.mx-0
        v-btn.error--text.float-left.ml-2(  text @click="removeRecord" style="margin-right:auto" ) {{$t('abstract_form.delete')}}

        v-btn(  text @click="editrecord.priority = !editrecord.priority"  ).transparent
          v-icon(color="orange" x-small v-if="editrecord.priority") mdi-circle
          v-icon(x-small v-if="!editrecord.priority") mdi-circle-outline

        v-btn(@click="updateRecord(editrecord)" color="success" text).mr-2  {{$t('models.save')}}

        v-btn(@click="cancel" color="warning" text).mr-2 {{$t('models.cancel')}}

      v-card-text.pt-8.pb-8
        v-text-field(
          outlined
          dense single-line
          :autofocus="editrecord.hasOwnProperty('isNew')"
          @mousedown.native.stop='()=>{return false}'
          @focus="onTitleFocus"
          v-model='editrecord.name'
          :rules="[rules.required]"
          :placeholder="$t('kanban.title')")
        v-textarea(
          outlined
          class=
          dense rows='2' auto-grow
        @mousedown.native.stop='()=>{return false}'
          :name="'desc' + editrecord._id"
          class=""
          :autofocus="!editrecord.hasOwnProperty('isNew')"
          v-model='editrecord.description'
          :placeholder="$t('kanban.description')"
          @keydown.enter.exact="filterEnter"
          @keyup.enter.exact="filterEnter"
        )
        div(style="display: flex; flex-direction: column;align-items: center; padding-top:0")
          v-btn-toggle.mb-2.transparent(v-model="editrecord.progress" @mousedown.native.stop='()=>{return false}' ).mb-4
            v-btn(v-for="progress in progressList " :value="progress.value" @click.stop="setProgress(progress.value)" dense text )
              v-icon() {{progress.icon}}
          v-btn-toggle.transparent( v-model="editrecord.taskType" @mousedown.native.stop='()=>{return false}' ).mb-4
            v-btn(v-for="typeItem in typesList "  :value="typeItem.value" dense text   )
              v-icon() {{typeItem.icon}}

          v-radio-group.nowrap(
            row
            class="urgency"
            @mousedown.native.stop='()=>{return false}'
            v-model='editrecord.urgency'
          )
            v-radio(
              v-for='urgency in urgencyList'
              :ref='urgency'
              :key='urgency.val'
              :active-class="urgency.class"
              :class="urgency.class"
              :color="urgency.class"
            ).mb-2
          v-select(outlined :label="$t('project.project')" dense v-model="editrecord.project_uuid" :items="projectsList" item-text="name" item-value="_id"  @mousedown.native.stop='()=>{return false}')
          div {{editrecord._id}}

</template>

<script>
import ProjectTask from '../../models/ProjectTask.js'
import { validation } from '@/utils'
export default {
  name: 'kanban-task-dialog-edit',
  props: {
    editrecord: {
      default: function () {},
      type: Object
    },
    update: {
      default: () => {},
      type: Function
    },
    remove: {
      default: () => {},
      type: Function
    },
    cancelEdit: {
      default: () => {},
      type: Function
    }

  },
  async mounted () {
    this.projectsList = await ProjectTask.getProjectList(this.$pouch)
  },
  data () {
    return {
      dialog: false,
      projectsList: [],
      typesList: ProjectTask.getTypesList(),
      urgencyList: ProjectTask.getUrgenciesList(),
      progressList: ProjectTask.getProgressList()

    }
  },
  computed: {
    borderColor () {
      // const item = ProjectTask.urgencyList().find(l => (this.editrecord.priority === l.value))
      return 'red'
    },
    rules () {
      return validation
    }
  },
  methods: {
    filterEnter (e) {
      if (e.code === 'NumpadEnter') {
        e.preventDefault()
        return false
      }
      return true
    },
    async updateRecord () {
      this.dialog = false
      await this.update(this.editrecord)
    },
    async cancel () {
      this.dialog = false
      await this.cancelEdit()
    },

    async removeRecord () {
      this.dialog = false
      await this.remove(this.editrecord)
    },
    onTitleFocus (ev) {
      // eslint-disable-next-line no-prototype-builtins
      if (this.editrecord.hasOwnProperty('isNew')) {
        ev.target.select()
      }
    },

    setProgress (p) {
      this.$nextTick(() => {
        if (this.editrecord.progress === undefined) {
          setTimeout(() => { this.editrecord.progress = p }, 100)
        }
      })
    }
  }
}
</script>
<style scoped>

</style>
