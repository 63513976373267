<template lang="pug">
  v-card.abstract-table.ma-1.transparent(flat)
    portal(to="pagetitle")
      v-container.text-capitalize(style="white-space: nowrap")
        | {{ $t(model.type+'.'+model.plural) }}
    portal(:to="$store.state.portalName")
      v-btn.text-lowercase.transparent(@click='toNew()')
        v-icon(small).mr-2 mdi-plus
        span {{ $t("buttons.add_item") }}
    //h3.text-capitalize  {{ $t(model.type+'.'+model.plural) }}
    v-data-table(
      :headers="headers"
      v-bind:items="modelData"
      :hide-default-header='$store.state.isMobile'
      :disable-pagination="tableProps.disablePagination"
      :search="search"
      :sort-by="tableProps.sortBy"
      :sort-desc="tableProps.sortDesc"
      :items-per-page="tableProps.itemsPerPage"
      @click:row="gotoEdit"
      :hide-default-footer="tableProps.hideDefaultFooter"
      dense
    )
      template(v-slot:top)
        v-card.pa-0(v-if="tableProps.useSearch" flat :class="$store.state.isMobile?'':'col-4'")
          v-text-field(
          v-model="search"
          label="Search"
          class="mx-4 col4")
      template(:slot="$store.state.isMobile?'item':'none'" slot-scope="props")
        tr
          td(:colspan="props.headers.length")
            v-card.pa-5.transparent(flat   @click="gotoEdit(props.item)")
              div(v-for="(column, id) in props.headers"  :key="column.value")
                .v-card-title(v-if="id===0" )
                  h3 {{column.value.split('.').reduce((o,i)=>o[i], props.item)}}
                .v-card-text.row.row--dense(v-if="id>0")
                    .col {{column.text}}
                    .col {{column.value.split('.').reduce((o,i)=>o[i], props.item)}}

</template>
<script>

export default {
  name: 'abstract-table',
  async mounted () {
    this.model.vue = this
    await this.getHeaders()

    await this.getData()
  },
  data () {
    return {
      modelData: [],
      headers: [],
      search: ''
    }
  },

  props: {
    model: {
      default: () => {},
      type: Function
    }
  },
  computed: {
    tableProps () {
      const defaults = {
        disablePagination: false,
        useSearch: true,
        itemsPerPage: 15,
        hideDefaultFooter: false,
        sortDesc: true,
        sortBy: '_id'
      }
      if (this.model.tableProps) {
        return Object.assign(defaults, this.model.tableProps)
      }
      return defaults
    }
  },
  methods: {
    async getHeaders () {
      let schema = await this.model.getListSchema()
      schema = JSON.parse(JSON.stringify(schema))
      this.headers = schema.map(x => { x.text = this.$t(this.model.type + '.' + x.text); return x })
    },
    async getData () {
      const data = await this.model.getListData()
      if (this.model.sortFunc !== undefined) {
        this.modelData = data.docs.sort(this.model.sortFunc)
        return
      }

      this.modelData = data.docs
    },

    async gotoEdit (item) {
      const params = {}
      params.id = await item.id
      const route = { name: 'edit-' + this.model.type.replace(/_/g, '-'), params: params }
      await this.$router.push(route)
    },
    toNew () {
      this.gotoEdit({ id: 'new' })
    },
    formatNumber (num) {
      const formatter = new Intl.NumberFormat(this.$i18n.locale, {
        style: 'currency',
        currency: 'EUR'
      })
      return formatter.format(num)
    }
  }
}
</script>
<style lang="sass">

</style>
