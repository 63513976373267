import ModelProto from '@/models/ModelProto'
import { v4 as uuid } from 'uuid'

export default class ProjectsKanboard extends ModelProto {
  static type = 'project_kanboard';
  static title = 'kanban';
  static plural = 'kanbans';
  static icon = 'mdi-table';

  static fields () {
    return {
      _id: uuid(),
      name: '',
      sort: 50,
      projects: [],
      isDefault: null
    }
  }

  static template () {
    return {
      uuid: undefined,
      name: 'Kanban',
      sort: 2,
      projects: [],
      isDefault: null
    }
  }

  static async getListSchema () {
    return [
      { value: 'doc.name', text: 'name', align: 'start', sortable: false },
      { value: 'doc.sort', text: 'sort', align: 'start', sortable: false }
    ]
  }

  static async getformSchema () {
    return {
      name: {
        type: 'text',
        label: 'name',
        rules: ['required'],
        outlined: true
      }
      // sort: {
      //   type: 'number',
      //   label: 'sort',
      //   rules: ['required'],
      //   outlined: true
      // }
      // isDefault: {
      //   type: 'checkbox',
      //   label: 'isDefault',
      //   outlined: true
      // }
      // projects: {
      //   type: 'text',
      //   label: 'Projects',
      //   outlined: true
      // }
    }
  }

  static tableProps = {
    disablePagination: true,
    useSearch: false,
    itemsPerPage: -1,
    sortBy: 'doc.sort',
    sortDesc: false,
    hideDefaultFooter: true
  }
}
