<template lang="pug">
  v-container
    v-switch(
      class="themetoggle"
      color="indigo"
      v-model="switchstate"
      @change="toggle_dark_mode"
      hide-details
      flat)
</template>
<script>
export default {
  name: 'ThemeToggle',
  data () {
    return {
      switchstate: true
    }
  },
  mounted () {
    const dark = this.$store.state.themeDark
    if (dark !== undefined) {
      this.$vuetify.theme.dark = dark === true
    } else if (
      window.matchMedia &&
      window.matchMedia('(prefers-color-scheme: dark)').matches
    ) {
      this.$vuetify.theme.dark = true
    }
    this.$store.commit('setThemeDark', this.$vuetify.theme.dark)
    this.switchstate = this.$vuetify.theme.dark
  },
  methods: {
    toggle_dark_mode: function () {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark
      this.$store.commit('setThemeDark', this.$vuetify.theme.dark)
    }
  }
}

</script>

<style scoped>

</style>
