<template lang="pug">
  v-card(flat style="max-width: 600px")
    v-icon( @click="()=>{this.$router.back()}") mdi-keyboard-backspace
    h3 Meds Table Settings
    v-data-table(
      :items="medNames"
      :headers="headers"
      item-key="name"
      dense
      hide-default-footer
      disable-pagination)
      template(v-slot:body="props")
        draggable(v-model="medNames"  tag="tbody" @end="dragend" animation='300' )
          tr(v-for="(med, index) in props.items" :key="index")
            td
              v-icon(small) mdi-arrow-all
            td
              v-checkbox.v-data-table__checkbox(dense v-model="med.visible" @click="update" false-value=0)
            td.row--dense {{med.name}}

</template>
<script>
import draggable from 'vuedraggable'
import Med from '../models/Med.js'

export default {
  name: 'medsChooser',
  components: {
    draggable
  },
  data () {
    return {
      xyz: [],
      medNames: [],
      selected: [],
      headers: [
        { value: 'sort', text: '', sortable: false },
        { value: 'visible', text: 'show', sortable: false },
        { value: 'name', text: 'Med', sortable: false }
      ]
    }
  },
  mounted () {
    this.getUniquMedList()
  },
  methods: {

    async  getUserSettings () {
      return await this.$getMedsSettings()
    },
    async getUniquMedList () {
      const existingMeds = await this.getUniquExistingMeds()
      const medsSettings = await this.$getMedsSettings()
      this.selected = medsSettings.medNames.filter(x => (x.visible === 1))
      const storedMedList = medsSettings.medNames.filter(x => (existingMeds.includes(x.name)))
      let storedMedNames = storedMedList.map(x => x.name)
      storedMedNames = [...new Set(storedMedNames)]
      const addMissing = existingMeds.filter(x => (!storedMedNames.includes(x)))
      addMissing.forEach(x => {
        storedMedList.push({
          name: x,
          visible: 1,
          sort: storedMedList.length + 1
        })
      })
      this.medNames = JSON.parse(JSON.stringify(storedMedList))

      return this.medNames
    },

    async dragend (ev) {
      const settings = await this.$getMedsSettings()
      this.medNames.forEach((item, i) => {
        this.medNames[i].sort = i
      })
      settings.medNames = this.medNames
      await this.$pouch.put(settings)
    },

    async getUniquExistingMeds () {
      const data = await Med.getListData(this.$pouch)
      const docs = data.docs
      const names = docs.map(x => x.doc.name)
      return [...new Set(names)]
    },

    async update () {
      const settings = await this.$getMedsSettings()
      settings.medNames = JSON.parse(JSON.stringify(this.medNames))

      await this.$updateMedSettings(settings)
    }
  }
}

</script>

<style>
.v-icon {
  cursor: pointer;
}

</style>
