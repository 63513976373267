import { shadeBlend } from '@/utils'
import colors from 'vuetify/lib/util/colors'

const basecolor = '#2F3946'

const primary = {
  base: shadeBlend(0, basecolor),
  darken1: shadeBlend(-0.2, basecolor),
  darken2: shadeBlend(-0.3, basecolor),
  darken3: shadeBlend(-0.4, basecolor),
  darken4: shadeBlend(-0.5, basecolor),
  lighten1: shadeBlend(0.2, basecolor),
  lighten2: shadeBlend(0.3, basecolor),
  lighten3: shadeBlend(0.4, basecolor),
  lighten4: shadeBlend(0.5, basecolor),
  lighten5: shadeBlend(0.5, basecolor),

  xyz: '#2A323C'
}
export const themeElegant = {
  options: {
    customProperties: true
  },
  dark: false,
  themes: {
    light: {
      primarySpecial: primary,
      primary: primary,
      secondary: '#2F3946',
      white: '#ffffff',
      black: '#2F3946',
      warning: colors.orange.lighten2,
      // hover: primary,
      accent: shadeBlend(0, basecolor)
    },
    dark: {
      primarySpecial: primary,
      primary: shadeBlend(0.3, basecolor),
      secondary: '#2F3946',
      white: '#C8D1E3',
      // hover: primary,
      black: primary,

      accent: primary

    }
  }
}
