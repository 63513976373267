import axios from 'axios'
export default {
  install (Vue, store, options = {}) {
    const parseJwt = function (token) {
      const base64Url = token.split('.')[1]
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
      const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      }).join(''))

      return JSON.parse(jsonPayload)
    }
    Vue.prototype.$parseJwt = parseJwt
    Vue.prototype.$refreshToken = async () => {
      const data = {
        refresh_token: store.state.refreshToken,
        token: store.state.refreshToken
      }

      return await axios.post(process.env.VUE_APP_AUTH_URL + '/token/refresh', data, { withCredentials: true }
      ).then((resp) => {
        const parsed = parseJwt(resp.data.token)
        if (parsed.sub !== store.state.lastUser) {
          store.commit('setToken', '')
          store.commit('setRefreshToken', '')
          store.commit('setCurrentUser', '')
          store.commit('setSyncstate', 'offline')
          return
        }
        store.commit('setToken', resp.data.token)
        store.commit('setRefreshToken', resp.data.refresh_token)
        store.commit('setCurrentUser', parsed.sub)
      }).catch(() => {
        // alert('debugme')
        // store.commit('setToken', '')
        // store.commit('setRefreshToken', '')
        // store.commit('setCurrentUser', '')
        // store.commit('setSyncstate', 'offline')
      })
    }
  }
}
