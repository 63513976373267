var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"abstract-table ma-1 transparent pb-16",attrs:{"flat":""}},[_c('portal',{attrs:{"to":"pagetitle"}},[_c('v-container',{staticClass:"text-capitalize",staticStyle:{"white-space":"nowrap"}},[_vm._v(_vm._s(_vm.$t('left_menu.history')))])],1),_c('v-data-table',{staticClass:"history-table",attrs:{"headers":_vm.headers,"footer-props":{'items-per-page-options': [25,50,75,100, -1] },"items-per-page":25,"items":_vm.items,"search":_vm.search,"dense":""},on:{"click:row":_vm.handleClick},scopedSlots:_vm._u([{key:"top",fn:function(ref){
var pagination = ref.pagination;
var options = ref.options;
var updateOptions = ref.updateOptions;
return [_c('div',{staticClass:"row"},[_c('v-card',{staticClass:"pa-0 transparent mx-5 row",attrs:{"flat":""}},[_c('v-text-field',{attrs:{"label":"Search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-card',{staticClass:"pa-0 transparent mb-2 mt-0",attrs:{"flat":""}},[_c('v-data-footer',{staticClass:"transparent",attrs:{"pagination":pagination,"items-per-page-options":[25,50,75,100,-1],"options":options,"items-per-page-text":"$vuetify.dataTable.itemsPerPageText"},on:{"update:options":updateOptions}})],1)],1)]}},{key:"item.changes",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('event-history-data',{ref:'toggles'+index,attrs:{"doc":item.changes}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }