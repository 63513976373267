<template lang="pug">
  div(:class="(expanded)?'transheight open':'transheight'").column
    v-simple-table
      tr(v-for="(row, i) in doc.data"  )
        td(style="width: 80px" ) {{row.op}}
          v-icon(:class="(expanded)?'chevron open':'chevron'" v-if="i<1") mdi-chevron-right
        td(style="width: 200px") {{JSON.stringify(row.path).replace('[','').replace(']','').replace('"','').replace('"','').replace(',',':')}}
        td(style="" ) {{row.val}}
      tr
        td _id
        td(colspan=2) {{doc._id}}
      tr
        td aggrId
        td(colspan=2) {{doc.aggrId}}
</template>

<script>
export default {
  name: 'event-history-data',
  mounted () {
  },
  props: {
    doc: {
      defaultValue: {},
      type: Object
    }
  },
  data () {
    return {
      expanded: false
    }
  },
  computed: {},
  methods: {
    toggle () {
      this.expanded = !this.expanded
    }
  }
}
</script>

<style scoped lang="sass">

.chevron
  transform: rotate(0)
  transition: transform 200ms ease-in

.chevron.open
  transform: rotate(90deg)
  transition: transform 200ms ease-in

.transheight
  max-height: 22px
  transition: max-height 300ms ease-out
  overflow: hidden

.transheight.open
  max-height: 500px
  transition: max-height 300ms ease-in

</style>
