<template lang="pug">
  v-card.transparent(flat )
    Logo
    v-list(nav dark flat floating )
      v-list-item(:to="{name:'kanban', params:{id:'prio'}}")
        v-list-item-icon
          v-icon mdi-table-row
        v-list-item-content
          v-list-item-title.text-capitalize {{ $t('left_menu.kanban') }}
      v-list-item( :to="{name:'eventHistory'}" )
        v-list-item-icon
          v-icon mdi-library
        v-list-item-content
          v-list-item-title.text-capitalize {{ $t('left_menu.history') }}

      //v-list-item(v-if="!$store.isMobile" :to="{name:'knowledge'}" )
      //  v-list-item-icon
      //    v-icon mdi-library
      //  v-list-item-content
      //    v-list-item-title.text-capitalize {{ $t('left_menu.knowledge') }}
      v-list-item(:to="{name:'medtable'}" v-if="isFuo")
        v-list-item-icon
          v-icon mdi-medical-bag
        v-list-item-content
          v-list-item-title.text-capitalize {{ $t('left_menu.medtable') }}

      v-list-group(prepend-icon="mdi-table")
        template.hidden-md-and-up(v-slot:activator)
          v-list-item-content
            v-list-item-title.text-capitalize
              | {{ $t('left_menu.tables') }}
        v-list-item( v-if="isFuo || item.type!=='medication'"
          dense :to="{name: item.type.replace(/_/g, '-')}"
          v-for="(item, id) in this.$models.filter(m=>{return m.menuId===1})" :key="id")
          v-list-item-icon
            v-icon() {{ item.icon }}
          v-list-item-content
            v-list-item-title.text-capitalize {{$t(item.type+'.'+item.plural)}}

      v-list-item(dense :to="{name:'settings'}")
        v-list-item-icon
          v-icon() mdi-cog
        v-list-item-content
          v-list-item-title.text-capitalize {{ $t('left_menu.settings') }}
      v-list-item(dense :to="{name:'admin'}" )
        v-list-item-icon
          v-icon mdi-account-cog-outline
        v-list-item-content
          v-list-item-title.text-capitalize {{$t('left_menu.admin')}}

      v-toolbar.transparent.elevation-0(flat)
        v-toolbar-items(style="margin-left: -20px")
          theme-toggle
          translation-switch
          dense-btn(v-if="false")
      v-divider
      LoginName
      v-divider

      v-list-item(style="text-align:center; font-size:85%"  dense :to="{name:'settings'}")
        v-list-item-content(style="text-align:center")
          center {{version}}
      v-list-item(style="text-align:center;font-size:75%"  dense :to="{name:'settings'}")
        v-list-item-content(style="text-align:center")
          center © 2021 Jost Muxfeldt
          center  All rights reserved.
      v-list-item(style="text-align:center;font-size:75%"  dense)
        v-list-item-content(style="text-align:center")
          center
            portal-target(name="update-button")
</template>

<script>
import Logo from './Logo'
import TranslationSwitch from './TranslationSwitch'
import denseBtn from './denseBtn'
import ThemeToggle from './ThemeToggle'
import LoginName from './LoginName'
const buildnum = require('../utils/metadata.json')

export default {
  name: 'left-menu',
  components: {
    Logo,
    TranslationSwitch,
    ThemeToggle,
    denseBtn,
    LoginName
  },
  computed: {
    version () {
      return process.env.VUE_APP_BUILD_NUMBER + buildnum.build
    },
    isFuo () {
      return (this.$store.state.currentUser === 'fuo') || (this.$store.state.currentUser === 'jmfuo')
    }
  }
}
</script>
