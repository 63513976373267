export default {
  install (Vue, store, options = {}) {
    Vue.prototype.$getMedsSettings = async () => {
      return Vue.prototype.$pouch.get('userMedsSettings').catch((err) => {
        if (err.name === 'not_found') {
          return {
            _id: 'userMedsSettings',
            medNames: []
          }
        } else { // hm, some other error
          throw err
        }
      }).then((configDoc) => {
        return configDoc
      })
    }
    Vue.prototype.$updateMedSettings = async (settings) => {
      await Vue.prototype.$pouch.put(settings)
    }
  }
}
