<template lang="pug">
  div.column {{title}}
</template>

<script>
export default {
  name: 'event-history-title',
  props: {
    aggrId: {
      defaultValue: '',
      type: String
    }
  },
  data () {
    return {
      title: 'test'
    }
  },
  mounted () {
    this.getAggregate(this.aggrId)
  },
  methods: {
    async getAggregate (id) {
      const record = await this.$pouch.get(id)
      this.title = record.name
    }
  }
}
</script>

<style scoped>

</style>
