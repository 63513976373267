<template lang="pug">
  div.d-inline-block
    v-btn(@click.native.stop="dialog = true"  text)
      v-icon(:color="(types.length || urgencies.length)?'orange':''" ) {{(types.length || urgencies.length)?'mdi-eye':'mdi-eye-outline'}}

    v-dialog(:content-class="fromBottom?'dialogBottom':'dialogTop'"   :transition="fromBottom?'dialog-bottom-transition':'dialog-top-transition'"
      v-model='dialog'
      max-width="600px"
      inset
    )
      template(v-slot:default="dialog")
        v-card
          v-toolbar(color="primarySpecial" ).white--text
            v-btn(@click="close" large text style="position: absolute; right: 0").white--text.mr-2
              v-icon mdi-close
            v-card-title {{$t('kanban.task_visibility')}}
          div(style="display: flex; flex-direction: column;align-items: center; padding-top:0").py-10

            h5.mb-2.mt-5 {{$t('kanban.visible_columns')}}
            v-btn-toggle.mb-2.transparent(multiple v-model="progress" )
              v-btn(v-for="progress in progressList " :value="progress.value" dense text   @click="updateSettings" )
                v-icon() {{progress.icon}}
            h5.my-2 {{$t('kanban.filters')}}
            v-btn-toggle.transparent(multiple v-model="types" )
              v-btn(v-for="typeItem in typesList "  :value="typeItem.value" dense text   @click="updateSettings" )
                v-icon() {{typeItem.icon}}
            br
            v-radio-group(
              class="urgency justify-center"
              row
              multiple
              @mousedown.native.stop='()=>{return false}'
              v-model='urgencies'
              style="margin-right: -25px"
            )
              v-radio(
                v-for='urgency in urgencyList'
                :ref='"urgency"+urgency.val'
                :value="parseInt(urgency.val)"
                @click.prevent="updateUrgencies(urgency.val)"
                :key='urgency.val'
                :active-class="urgency.class"
                :class="urgency.class"
                :color="urgency.class"
                )

</template>

<script>
import ProjectTask from '../../models/ProjectTask.js'

export default {
  name: 'kanban-filter-dialog',
  data: function () {
    return {
      dialog: false,
      urgencies: [],
      types: [],
      progress: [],
      previouslySelected: [],
      typesList: ProjectTask.getTypesList(),
      urgencyList: ProjectTask.getUrgenciesList(),
      progressList: ProjectTask.getProgressList()
    }
  },
  mounted () {
    const settings = this.$store.state.appSettings
    this.urgencies = settings.urgenciesVisible
    this.types = settings.typesVisible
    this.progress = settings.progressVisible
  },
  props: {
    fromBottom: {
      default: false,
      type: Boolean
    }
  },
  methods: {
    close () {
      this.dialog = false
    },
    updateSettings () {
      this.$nextTick(() => {
        const settings = this.$store.state.appSettings
        settings.progressVisible = this.progress
        settings.typesVisible = this.types
        settings.urgenciesVisible = this.urgencies
        this.$store.commit('setAppSettings', settings)
      })
    },
    updateUrgencies (v) {
      this.$nextTick(() => {
        const ind = this.previouslySelected.indexOf(parseInt(v))
        if (ind !== -1) {
          this.$refs['urgency' + v][0].$refs.input.checked = false
          this.urgencies.splice(ind, 1)
        }
        this.previouslySelected = this.urgencies
        this.updateSettings()
      })
    },
    reset () {
      // const value_current = this.$refs.inner_radio.radio.value
      // const value_new =     this.$refs.inner_radio.value
      // if (value_current === value_new) {
      //   this.$nextTick(() => {
      //     this.$refs.inner_radio.$emit('change', null)
      //   })
    }

  }
}
</script>
<style scoped>

>>> .dialogTop {
  align-self: flex-start;
  margin-top: 0
}
>>> .dialogBottom {
  align-self: flex-end;
  margin-bottom: 0

}
</style>
