<template lang="pug">
  v-card.abstract-table.ma-1.transparent(flat)
    portal(to="pagetitle")
      v-container.text-capitalize(style="white-space: nowrap")
        | {{ $t(model.type+'.'+model.plural) }}
    portal(:to="$store.state.portalName")
      v-btn.text-lowercase(@click='toNew()' text)
        span  {{ $t('buttons.add_item') }}
        v-icon(small) mdi-plus
    v-btn(text :href="mailTo")
      span  mail
      v-icon(small) mdi-mail
    v-data-table(
      :headers="headers"
      v-bind:items="modelData"
      :hide-default-header='$store.state.isMobile'
      :disable-pagination="tableProps.disablePagination"
      :items-per-page="tableProps.itemsPerPage"
      :hide-default-footer="tableProps.hideDefaultFooter"
      dense
    )
      template(v-slot:top)
        v-card.pa-0(v-if="tableProps.useSearch" flat :class="$store.state.isMobile?'':'col-4'")
          v-text-field(
            v-model="search"
            label="Search"
            class="mx-4 col4")
      template(:slot="$store.state.isMobile?'item':'none'" slot-scope="props")
        tr
          td(:colspan="props.headers.length")
            v-card.pa-5.transparent(flat)
              div(v-for="(column, id) in props.headers"  :key="column.value")
                .v-card-title(v-if="id===0" )
                  h3 {{column.value.split('.').reduce((o,i)=>o[i], props.item)}}
                .v-card-text.row.row--dense(v-if="id>0")
                  .col {{column.text}}
                  .col {{column.value.split('.').reduce((o,i)=>o[i], props.item)}}

</template>
<script>
import moment from 'moment'

export default {
  name: 'abstract-table',
  async mounted () {
    this.model.vue = this
    await this.getHeaders()
    await this.getData()
    await this.firstRow()
  },
  data () {
    return {
      modelData: [],
      headers: [],
      search: '',
      mailTo: ''
    }
  },

  props: {
    model: {
      default: () => {
      },
      type: Function
    }
  },
  computed: {
    tableProps () {
      return {
        disablePagination: false,
        useSearch: false,
        itemsPerPage: -1,
        hideDefaultFooter: false,
        sortDesc: true,
        sortBy: '_id'
      }
    }
  },
  methods: {
    async getHeaders () {
      let header = await this.getUniquMedList()
      header = header.map(item => {
        return {
          text: item.name,
          value: item.name,
          sortable: false
        }
      })
      this.headers = [{
        text: this.$t('medication.date'),
        value: 'date'
      }, ...header]
    },
    async getData () {
      let data = await this.model.getListData()
      const items = []
      data.docs.sort(this.sortFunc)
      data = data.docs.map(item => item.doc)
      const medList = await this.getUniquMedList()
      const medNames = medList.map(x => x.name)
      data = data.filter(x => (medNames.includes(x.name)))
      this.getUniquDateList(data).forEach((date) => {
        const dt = {}
        const found = data.filter(item => (item.date === date))
        const flabels = found.map(fnd => fnd.name)
        dt.date = date
        found.forEach(fnd => {
          if ((fnd.dossage === 0) || fnd.dossage === '0') {
            dt[fnd.name] = 'abgesetzt'
          } else {
            dt[fnd.name] = fnd.dossage + fnd.unit + this.countOnes(fnd.instruction)
          }
        })
        medList.forEach(medName => {
          if (!flabels.includes(medName.name)) {
            dt[medName.name] = this.getLastValue(data, medName.name, dt.date)
          }
        })
        items.push(dt)
      })
      this.modelData = items.sort((a, b) => ((new Date(b.date)) - (new Date(a.date))))
    },
    countOnes (str) {
      const count = str.split('1').length - 1
      if (count > 0) {
        return ' ' + count + 'x'
      }
      return ''
    },
    async firstRow () {
      const firstrow = this.modelData[0]
      const rows = await this.getUniquMedList()
      let ret = 'Jost Muxfeldt, geb. 02.10.1959%0D%0A%0D%0A%0D%0A'
      ret = ret + 'Aktuelle Medikamente ' + moment(firstrow.date).format('DD.MM.YYYY') + '%0D%0A%0D%0A'
      rows.forEach(item => {
        if (firstrow[item.name] !== '') {
          ret = ret + item.name + ':   ' + firstrow[item.name] + '%0D%0A'
        }
      })
      this.mailTo = 'mailto:?subject=medikamente&body=' + ret
    },
    getLastValue (data, column, date) {
      let earlier = data.filter(d => { return new Date(d.date) < (new Date(date)) })
      earlier = earlier.filter(x => (x.name === column))
      if (earlier.length > 0) {
        const last = earlier[earlier.length - 1]
        if ((last.dossage === 0) || last.dossage === '0') {
          return ''
        }
        return last.dossage + last.unit + this.countOnes(last.instruction)
      }
      return ''
    },

    sortFunc (a, b) {
      const da = new Date(a.doc.date)
      const db = new Date(b.doc.date)
      return da - db
    },

    async gotoEdit (item) {
      const params = {}
      params.id = await item.id
      const route = {
        name: 'edit-' + this.model.type.replace(/_/g, '-'),
        params: params
      }
      await this.$router.push(route)
    },
    async getUniquMedList () {
      const settings = await this.$getMedsSettings()
      return settings.medNames.filter(x => (x.visible > 0))
    },
    getUniquDateList (data) {
      return [...new Set(data.map(item => item.date))]
    },
    toNew () {
      this.gotoEdit({ id: 'new' })
    },
    formatNumber (num) {
      const formatter = new Intl.NumberFormat(this.$i18n.locale, {
        style: 'currency',
        currency: 'EUR'
      })
      return formatter.format(num)
    }
  }
}
</script>
<style lang="sass">

</style>
