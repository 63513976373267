<template lang="pug">
  div
    div(v-if="!isLoggedIn" style="font-size:90%; color: red; text-align:center")
      | {{lastUser}} {{$t('left_menu.offline')}}
    v-list-item
      v-list-item-content
        v-btn(style="font-weight: 100; font-size: 95%;text-transform: none" text small @click="toggleLogin"  ) {{buttonLabel}}
          v-icon(color="white" style="margin-left:5px" ) {{isLoggedIn?'mdi-connection':'mdi-logout'}}
</template>
<script>
export default {
  name: 'SyncProgress',
  computed: {
    isLoggedIn () {
      return this.$store.state.currentUser !== ''
    },
    buttonLabel () {
      if (this.isLoggedIn) {
        return '[ ' + this.$store.state.currentUser + ' ] '// + this.$t('left_menu.logout')
      }
      return this.$t('left_menu.login')
    },
    lastUser () {
      return this.$store.state.lastUser
    }
  },
  methods: {
    toggleLogin () {
      if (this.isLoggedIn) {
        this.$router.replace('/logout')
      } else {
        this.$router.replace('/login')
      }
    }
  }
}
</script>
