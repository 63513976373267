import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store/store'
import moment from 'vue-moment'
import VuetifyDialog from 'vuetify-dialog'
import 'vuetify-dialog/dist/vuetify-dialog.css'
import i18n from './i18n'
import PortalVue from 'portal-vue'
import VCurrencyField from 'v-currency-field'
import { VTextField } from 'vuetify/lib'
import PouchdbSyncer from './plugins/pouchdbsyncer'
import TokenChecker from './plugins/tokenChecker'
import globalClientSettings from './plugins/globalClientSettings'
import globaUserSettings from './plugins/globaUserSettings'
import globalMedsSettings from './plugins/globalMedsSettings'
import './registerServiceWorker'
import vClickOutside from 'v-click-outside'
import ModelProto from './models/ModelProto'
Vue.use(vClickOutside)
Vue.use(TokenChecker, store)
Vue.use(PouchdbSyncer, store)
Vue.use(globalClientSettings, store)
Vue.use(globaUserSettings, store)
Vue.use(globalMedsSettings, store)
if (location.hostname.indexOf('projects.glyph.de') === -1) {
  Vue.config.debug = true
} else {
  Vue.config.devtools = false
  Vue.config.debug = false
  Vue.config.silent = true
}

Vue.component('v-text-field', VTextField)
Vue.use(VCurrencyField, {
  locale: 'de-DE',
  decimalLength: 2,
  autoDecimalMode: true,
  min: 0,
  max: null,
  defaultValue: 0,
  valueAsInteger: false,
  allowNegative: false
})
Vue.use(PortalVue)

Vue.config.productionTip = false

Vue.use(VuetifyDialog, {
  context: {
    vuetify
  }
})

// import './scss/theme.scss';
Vue.config.productionTip = false
Vue.use(require('vue-moment'), {
  moment
})
const vue =
  new Vue({
    i18n,
    vuetify,
    store,
    router,
    render: h => h(App)
  }).$mount('#app')
export default vue
