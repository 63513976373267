<template lang="pug">
  div.d-inline-block
    //v-dialog(v-model="dialog" content-class="dialogTop"  persistent=false dense max-width="500" transition="dialog-top-transition")
      template(v-slot:activator='{ on, attrs }')
    v-btn.text-capitalize(@click="dialog=!dialog" text )  &nbsp;
      v-icon mdi-format-list-text
    portal(to="temp-drawer" )
      v-navigation-drawer.temp( v-model="dialog" app width="auto"  floating right temporary )
        v-card.transparent(flat ).rounded-0
          v-toolbar(color="primarySpecial"  ).text-no-wrap.justify-end.white--text
            v-row.justify-end
              v-card.transparent(flat style="margin-right:auto").ml-4.mt-2.white--text.text-capitalize {{$t('project.projects')}}
              v-btn(text to="/project/edit/new").white--text
                v-icon mdi-plus
              v-btn(text @click="dialog=!dialog").white--text
                  v-icon mdi-close
          v-list(dense flat max-width="600" height="100%").float-right
            v-list-item(dense v-for='(proj, index) in allProjects' :key='index')
              v-list-item-icon(style="position:relative; top:-20px")
                v-checkbox(v-model="checked" v-if="$route.params.id ==='prio' " disabled)
                v-checkbox(v-if="$route.params.id !=='prio' " dense v-model='activeProjects' :value='proj._id' hide-details)
              v-list-item-content {{proj.name}}
              v-list-item-icon(style="position:relative; top:-5px")
                v-btn(dense small fab text :to="'/project/edit/'+proj._id")
                  v-icon mdi-pencil
</template>
<script>

export default {
  name: 'kanban-tools',
  async mounted () {
    await this.getProjects()
  },
  props: {
    kanboard: {
      type: Object
    }

  },
  data () {
    return {
      dialog: false,
      checked: 1,
      allProjects: []
    }
  },
  computed: {
    activeProjects: {
      get () {
        return this.kanboard.projects || []
      },
      async set (vals) {
        this.kanboard.projects = vals
        await this.$upsert(this.kanboard)
        await this.getProjects()
        this.$emit('projectChange')
      }

    }
  },
  methods: {
    async getProjects () {
      await this.$pouch.createIndex({
        index: { fields: ['name'] }
      })
      const items = await this.$pouch.find({ selector: { name: { $gte: null }, type: 'project' }, sort: ['name'] })
      this.allProjects = items.docs.map(x => {
        return {
          name: x.name,
          _id: x._id
        }
      })
    }
  }

}
</script>

<style scoped>
>>> .dialogTop {
  position:absolute;
  top:0;
  right:-23px;
  margin-top: 0
}
</style>
