<template lang="pug">
  .abstract-form
    portal(to="pagetitle")
      v-container.text-capitalize(style="white-space: nowrap")
        | {{ $t(translationTitle) }}
    v-card(flat :style="isMobile? 'padding: 20px 0 100px 0' :''")
      v-container(fluid)
        v-form(:lazy-validation='true' ref="form" style="padding: 50px 0; max-width: 600px").lightened-surface.pa-5
          v-form-base(:model="record" :schema="formSchema")
        //v-banner(outlined style="max-width: 600px")  Rev: {{record._rev}}
        br
        v-alert(v-if="!allowDelete" outlined dense type="info" style="max-width: 600px") {{$t("models.nodelete")}}
    portal(:to="$store.state.portalName")
          v-btn.text-lowercase.transparent( @click="cancel"  )
            span {{$t("models.cancel") }}
            //v-icon(small) mdi-close
          v-btn.text-lowercase.transparent( @click="save" type="submit"  )
            span {{$t("models.save")}}
            //v-icon(small) mdi-content-save-outline
          v-btn.text-lowercase.error--text.transparent(v-if="allowDelete" @click="remove"  )
            span.error--text.text--darken-1 {{ $t("models.delete") }}
</template>
<script>
import { getNow, validation } from '@/utils'
import VFormBase from 'vuetify-form-base'
import { v4 as uuid } from 'uuid'

export default {

  components: { VFormBase },
  name: 'abstract-type-form',
  async beforeMount () {
    this.model.vue = this
    this.formSchema = await this.getFormSchema()
    const id = this.$route.params.id
    this.translationTitle = this.model.type + '.' + this.model.title

    if (id === 'new') {
      this.record = {}
      const fields = this.model.fields()
      for (const [key] of Object.entries(this.formSchema)) {
        if (Object.prototype.hasOwnProperty.call(fields, key)) {
          this.record[key] = fields[key]
        } else {
          this.record[key] = ''
        }
      }
      return
    }
    this.record = await this.$pouch.get(this.$route.params.id)
    this.revision = this.record._rev
    this.$pouch.get(this.record._id, {
      revs: true,
      revs_info: true,
      open_revs: 'all'
    }).then((res) => {
      const revs = res[0].ok._revisions

      this.revisions = revs.ids.map((v, i) => { return { rev: (revs.start - i) + '-' + v } })
    })
  },

  props: {
    model: {
      default: () => {
      },
      type: Function
    }
  },
  data () {
    return {
      translationTitle: '',
      record: {},
      revision: '',
      formSchema: {},
      revisions: [],
      isMobile: true
    }
  },
  computed: {
    indexRouteName () {
      return this.model.type.replace(/_/g, '-')
    },
    allowDelete () {
      return !this.record.isDefault
    }
  },

  methods: {
    async getFormSchema () {
      const schema = await this.model.getformSchema()
      const obj = JSON.parse(JSON.stringify(schema))
      for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          // fix columns
          if (!Object.prototype.hasOwnProperty.call(obj[key], 'col')) {
            obj[key].col = 12
          }
          // translat label
          if (Object.prototype.hasOwnProperty.call(obj[key], 'label')) {
            obj[key].label = this.$t(this.model.type + '.' + obj[key].label)
          }
          // get validation function
          if (Object.prototype.hasOwnProperty.call(obj[key], 'rules')) {
            if (obj[key].rules.find(x => x === 'required')) {
              if (Object.prototype.hasOwnProperty.call(obj[key], 'class')) {
                obj[key].class.required = true
              } else {
                obj[key].class = { required: true }
              }
            }
            obj[key].rules = obj[key].rules.map(v => validation[v])
          }
          if (Object.prototype.hasOwnProperty.call(obj[key], 'options')) {
            obj[key].options = obj[key].options.map(x => { x.label = this.$t(x.label); return x })
          }
        }
      }
      return obj
    },
    cancel () {
      this.$router.go(-1)
    },
    async remove () {
      const res = await this.$dialog.confirm({
        title: this.$t('abstract_form.really_delete'),
        text: '',
        actions: {
          false: this.$t('abstract_form.cancel'),
          true: this.$t('abstract_form.delete')
        }
      })
      if (res) {
        await this.model.beforeDelete(this.record)
        await this.$pouch.remove(this.record)
        await this.$syncDb()
        if (this.record.type === 'project_kanboard') {
          await this.$router.push('/kanban/prio')
          return
        }
        await this.$router.go(-1)
      }
    },
    async save () {
      if (!this.$refs.form.validate()) {
        return
      }

      if (this.record._id === undefined) {
        this.record.createdAt = getNow().toISOString()
        this.record.type = this.model.type
        this.record.isNew = true
        this.record._id = uuid()
        await this.$upsert(this.record)
        if (this.record.type === 'project_kanboard') {
          await this.$syncDb()
          await this.$router.push('/kanban/' + this.record._id)
          return
        }
      } else {
        await this.$upsert(this.record)
      }
      await this.$syncDb()
      await this.$router.go(-1)
    }
  }
}
</script>
<style lang="sass">
.abstract-form
  .v-item-group
    background-color: transparent !important
    margin-bottom: 20px

</style>
