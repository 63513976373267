import Vue from 'vue'
import VCurrencyField from 'v-currency-field'
import { themeElegant } from '@/plugins/themeElegant'
import Vuetify, {
  VRow,
  VCol,
  VTextField,
  VTooltip,
  VCheckbox,
  VSelect,
  VRadio,
  VRadioGroup,
  VDatePicker,
  VTextarea,
  VBtnToggle,
  VBtn,
  VIcon
} from 'vuetify/lib'
import { Ripple, Intersect, Touch, Resize } from 'vuetify/lib/directives'
Vue.use(Vuetify, {
  components: { VRow, VTooltip, VCol, VTextField, VCheckbox, VSelect, VRadio, VRadioGroup, VDatePicker, VTextarea, VCurrencyField, VBtnToggle, VBtn, VIcon },
  directives: { Ripple, Intersect, Touch, Resize },
  locale: 'de-DE',
  decimalLength: 2,
  autoDecimalMode: true,
  min: null,
  max: null,
  defaultValue: 0,
  valueAsInteger: false,
  allowNegative: true

})

export default new Vuetify({
  theme: themeElegant

})
