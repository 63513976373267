<template lang="pug">
  v-card(flat)
    portal(to="pagetitle")
      v-container.text-capitalize(style="white-space: nowrap")
        | {{$t('left_menu.settings')}}
    nav
      v-list( nav )
        v-list-item()
          v-btn(outlined :to="{name:'medSettings'}") {{ $t('settings.med_settings') }}
            v-list-item-icon
              v-icon mdi-chevron-right
        v-divider
        v-list-item()
          v-list-item-title {{$t('settings.local_settings')}}
        v-list-item()
          v-btn(@click="clearMem" outlined style="text-transform: none"   color="error" ) {{$t('settings.reset_app')}}
        v-list-item()
          v-btn(@click="handleExport" outlined style="text-transform: none"   color="success" ) {{$t('settings.backup_db')}}
        v-list-item()
          v-btn(@click="aggregateEvents" outlined style="text-transform: none"   color="success" )  (re-) Aggreegate Events

          //v-btn(@click="loadDefaults" text style="text-transform: none"   color="error" ) {{$t('settings.load_defaults')}}
</template>

<script>
import medsChooser from './MedsChooser'
import globalSettings from './GlobalClientSettings'
import axios from 'axios'

export default {
  name: 'AppSettingsForm',
  components: { medsChooser, globalSettings },
  watch: {
    isMobile () {
    }
  },
  computed: {
    settings () {
      return this.$store.state.appSettings
    },
    isFuo () {
      return (this.$store.state.currentUser === 'fuo') || (this.$store.state.currentUser === 'jmfuo')
    },
    isMobile () {
      const val = this.$vuetify.breakpoint.width < 769
      this.$store.commit('setIsMobile', val)
      return val
    }
  },
  methods: {
    updateSettings (ev, key) {
      const settings = { ...this.$store.state.appSettings }
      if (key === 'Tables') {
        settings[key] = ev
        this.$store.commit('setAppSettings', settings)
        return
      }
      if (key === 'archiveVisible') {
        settings[key] = ev
        this.$store.commit('setAppSettings', settings)
        return
      }
      if (key === 'medsVisible') {
        settings[key] = ev
        this.$store.commit('setAppSettings', settings)
        return
      }
      settings[key] = ev.target.value
      this.$store.commit('setAppSettings', settings)
    },

    async clearMem () {
      const res = await this.$dialog.error({
        title: this.$t('settings.really_reset_app'),
        text: this.$t('settings.really_reset_app_text'),
        actions: {
          false: this.$t('settings.cancel'),
          true: this.$t('settings.reset_app')
        }
      })
      if (res) {
        await this.$store.commit('setCurrentUser', '')
        await this.$store.commit('setLastUser', '')
        await this.$store.commit('setSyncstate', 'offline')
        await axios.get(process.env.VUE_APP_AUTH_URL + '/logout',
          { withCredentials: true })
        await window.indexedDB.databases().then((r) => {
          for (let i = 0; i < r.length; i++) window.indexedDB.deleteDatabase(r[i].name)
        })
        localStorage.clear()
        location.reload()
      }
    },

    async handleExport () {
      await this.$syncDb()
      await this.$pouch.allDocs({ include_docs: true }, (error, doc) => {
        if (error) {
          console.error(error)
        } else {
          let filename = 'projects_backup.json'
          if (this.$store.state.lastUser) {
            filename = 'projects_backup_' + this.$store.state.lastUser + '.json'
          }
          if (this.$store.state.currentUser) {
            filename = 'projects_backup_' + this.$store.state.currentUser + '.json'
          }
          this.download(
            JSON.stringify(doc.rows.map(({ doc }) => doc)),
            filename,
            'text/plain'
          )
        }
      })
    },
    async aggregateEvents () {
      await this.$aggregateEvents()
    },

    download (data, filename, type) {
      const file = new Blob([data], { type: type })
      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(file, filename)
      } else { // Others
        const a = document.createElement('a')
        const url = URL.createObjectURL(file)
        a.href = url
        a.download = filename
        document.body.appendChild(a)
        a.click()
        setTimeout(function () {
          document.body.removeChild(a)
          window.URL.revokeObjectURL(url)
        }, 0)
      }
    }

  }
}
</script>

<style scoped>

</style>
