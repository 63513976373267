<template lang="pug">
  div
    portal(to="pagetitle")
      v-container.text-capitalize(style="white-space: nowrap") {{ $t('left_menu.login') }}
    div
      //h1.title  Authentication
      //br
      v-form(flat v-on:submit.prevent="login" style="padding: 50px 0; max-width: 600px")
        span(style="font-size:80% ")  {{lastUser}}
        div
            v-text-field( v-model="username" outlined type="text"  :label="$t('settings.userName')")
        div
          v-text-field( v-model="password" outlined type="password"  :label="$t('settings.userPassword')")
        v-btn(type="submit" text  class="btn btn-primary" ) {{ $t('left_menu.login') }}
          v-icon mdi-login
    v-banner.red--text(style="max-width: 600px; border-color:red" outlined v-if="message!==''") {{message}}
</template>

<script>

import axios from 'axios'

export default {
  data () {
    return {
      username: '',
      password: '',
      message: ''
    }
  },
  computed: {
    settings () {
      return this.$store.state.appSettings
    },
    lastUser () {
      return this.$store.state.lastUser
    }
  },
  methods: {
    async login (e) {
      let destroy = false
      const payload = {
        username: this.username,
        password: this.password
      }
      if ((payload.username !== this.$store.state.lastUser) && (this.$store.state.lastUser !== '')) {
        const dialog = await this.$dialog.error({
          title: this.$t('settings.new_user'),
          text: this.$t('settings.new_user_text'),
          actions: {
            false: this.$t('settings.cancel'),
            true: this.$t('settings.delete_and_login')
          }
        })
        if (!dialog) {
          return
        }
        destroy = true
      }

      const response = await axios.post(process.env.VUE_APP_AUTH_URL + '/login_check', payload, { withCredentials: true }).catch((err) => {
        console.log(err)
      })

      if (response && response.status === 200) {
        const parsed = this.$parseJwt(response.data.token)
        await this.$store.commit('setToken', response.data.token)
        await this.$store.commit('setRefreshToken', response.data.refresh_token)
        await this.$store.commit('setCurrentUser', parsed.sub)
        await this.$store.commit('setLastUser', parsed.sub)
        await this.$store.commit('setSyncstate', 'current')
        setTimeout(async () => {
          if (destroy) {
            await this.$pouch.destroy()
          }
          location.reload()
        }, 500)
        await this.$router.push('/kanban/prio')
      }
    }

  }
}
</script>
