<template lang="pug">
  v-container
    v-icon(:color="syncing.color" ref="clickable" v-on:click="syncData"
       v-if="$store.state.syncstate === 'current'") {{syncing.icon}}
    v-icon(:color="syncing.color" v-on:click="syncData"  v-if="$store.state.syncstate !== 'current'") {{syncing.icon}}
</template>
<script>
export default {
  name: 'SyncProgress',
  computed: {
    syncing () {
      const syncstring = {
        offline: { color: 'red', icon: 'mdi-sync-off' },
        waiting: { color: 'orange', icon: 'mdi-dots-horizontal' },
        syncing: { color: 'orange', icon: 'mdi-autorenew mdi-spin' },
        current: { color: 'green', icon: 'mdi-autorenew' }
      }
      return syncstring[this.$store.state.syncstate]
    }
  },
  methods: {
    syncData () {
      this.$syncDb()
    }
  }
}
</script>
