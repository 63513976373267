import { render, staticRenderFns } from "./EventHistoryData.vue?vue&type=template&id=c4b0fa7c&scoped=true&lang=pug&"
import script from "./EventHistoryData.vue?vue&type=script&lang=js&"
export * from "./EventHistoryData.vue?vue&type=script&lang=js&"
import style0 from "./EventHistoryData.vue?vue&type=style&index=0&id=c4b0fa7c&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c4b0fa7c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
installComponents(component, {VIcon,VSimpleTable})
