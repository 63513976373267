<template lang="pug">
  v-app#app.transparent(v-if="isLoaded" :style="{background: $vuetify.theme.themes[themeVariant].background}" croll-behavior="hide" scroll-threshold="500")
    v-navigation-drawer.vert-gradient.noprint( v-model="drawer" app color="primarySpecial" width="220" floating )
      LeftMenu
    portal-target(name="temp-drawer" )

    v-app-bar.noprint(color="primarySpecial"  dense dark app noprint)
      v-app-bar-nav-icon( color="white" @click="drawer = !drawer")
      v-toolbar-items
        v-container(v-if="!drawer" style="color: rgba(255,255,255, 0.4)") GlyphDE
        portal-target(name="pagetitle" style="color: white")
        v-container( v-if="updateExists && !isElectron" @click="refreshApp" style="cursor: pointer;line-height:110%;text-align:center;font-size: 75%;color: rgba(255,255,255, 0.4)") new version <br> available
        portal(to="update-button")
          v-btn(v-if="!isElectron" @click="winReload()" x-small text) reload app
      v-spacer
      portal-target(name="topTools" style="color: white").v-btn-toggle.v-btn-toggle--borderless
      v-toolbar-items
        SyncProgress
    v-main(ref="mainContainer").mx-md-4.my-4.mx-sm-0.transparent
      v-fade-transition( name="view" mode="out-in" appear :duration="{ enter: 150, leave: 150 }")
        div(:key="$route.fullPath")
          router-view(origin="center center" )
    v-bottom-navigation.footer-bar.darken-2.noprint(
      v-if="showPageNav"
         fixed
       :class="$vuetify.theme.dark ? 'primarySpecial':''"
      :style="'height: 80px'+(drawer?'padding-left: 200px':'')")
      v-container
        v-row.justify-center(align="center" )
            portal-target(name="pagetools" ).v-btn-toggle.v-btn-toggle--borderless
      v-spacer
</template>

<script>
import LeftMenu from '@/components/LeftMenu'
import TranslationSwitch from '@/components/TranslationSwitch'
import ThemeToggle from '@/components/ThemeToggle'
import SyncProgress from './components/SyncProgress'

export default {
  components: {
    LeftMenu,
    TranslationSwitch,
    ThemeToggle,
    SyncProgress
  },
  data: () => ({
    timer: '',
    drawer: undefined,
    greeting: '',
    isLoaded: false,
    refreshing: false,
    registration: null,
    updateExists: false

  }),

  async beforeMount () {
    document.body.classList.add(this.themeVariant)
    this.$i18n.locale = this.$store.state.lastLocale
    document.addEventListener(
      'swUpdated', this.showRefreshUI, { once: true }
    )
    if (navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener(
        'controllerchange', () => {
          if (this.refreshing) return
          this.refreshing = true
          window.location.reload()
        }
      )
    }
    await new Promise(resolve => setTimeout(resolve, 500))
    this.isLoaded = true

    if (this.$router.mode !== 'hash') {
      await this.$router.push('/kanban/prio')
    }
  },
  watch: {
    isMobileLandscape () {},
    isMobile () {},
    portalName () {
    }
  },
  computed: {
    themeVariant () {
      if (this.$vuetify.theme.dark) {
        document.body.classList.add('dark')
        document.body.classList.remove('light')
      } else {
        document.body.classList.add('light')
        document.body.classList.remove('dark')
      }
      return this.$vuetify.theme.dark.toString() ? 'dark' : 'light'
    },
    showPageNav () {
      return this.isMobile && !this.isMobileLandscape
    },
    isMobileLandscape: function () {
      return this.isMobile && (window.innerWidth > window.innerHeight)
    },
    isMobile () {
      return this.$vuetify.breakpoint.width < 920
    },
    isKanbanRoute () {
      return this.$route.name === 'kanban'
    },

    portalName () {
      if (this.isMobile && !this.isMobileLandscape) {
        this.$store.commit('setPortalName', 'pagetools')
        return 'pagetools'
      }
      this.$store.commit('setPortalName', 'topTools')
      return 'topTools'
    },
    isElectron () {
      const userAgent = navigator.userAgent.toLowerCase()
      return userAgent.indexOf(' electron/') > -1
    }
  },

  methods: {
    winReload () {
      window.location.reload()
    },
    refreshApp () {
      this.updateExists = false
      if (!this.registration || !this.registration.waiting) {
        return
      }
      this.registration.waiting.postMessage('skipWaiting')
    },
    showRefreshUI (e) {
      this.registration = e.detail
      this.updateExists = true
    }
  }

}
</script>

<style lang="sass">
@import './scss/themeElegant'

/** do not remove */
</style>
