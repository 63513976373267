import ModelProto from '@/models/ModelProto'
import { v4 as uuid } from 'uuid'
import { getNow } from '@/utils'

export default class Med extends ModelProto {
  static type = 'medication';
  static title = 'med';
  static plural = 'meds';
  static icon = 'mdi-format-list-checks';
  static tableProps = { itemsPerPage: -1 }
  static sortFunc = function (a, b) {
    const da = new Date(a.doc.date)
    const db = new Date(b.doc.date)
    return db - da
  }

  static fields () {
    return {
      uuid: uuid(),
      date: '',
      name: 'Prednisolon',
      instruction: '1-0-0-0',
      unit: 'mg',
      dosage: 0,
      created_at: getNow().format('YYYY-MM-DD HH:mm:ss')
    }
  }

  static async getListSchema () {
    return [
      { value: 'doc.date', text: 'date', align: 'start', sortable: true },
      { value: 'doc.name', text: 'name', align: 'start', sortable: true },
      { value: 'doc.dossage', text: 'dossage', align: 'start', sortable: false },
      { value: 'doc.instruction', text: 'instruction', align: 'start', sortable: false },
      { value: 'doc.unit', text: 'unit', align: 'start', sortable: false }
    ]
  }

  static async getformSchema () {
    return {
      date: { type: 'text', label: 'date', rules: ['required'], outlined: true },
      name: { type: 'text', label: 'name', rules: ['required'], outlined: true },
      instruction: { type: 'text', label: 'instruction', rules: ['required'], outlined: true },
      unit: { type: 'text', label: 'unit', rules: ['required'], outlined: true },
      dossage: { type: 'text', label: 'dossage', rules: ['required'], outlined: true }
    }
  }
}
