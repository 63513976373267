<template lang="pug">
  v-card(flat style="max-width: 800px")
    v-icon( @click="()=>{this.$router.back()}") mdi-keyboard-backspace
    h3  {{$t('settings.client_settings')}}
      hr
      br
      v-form
        strong {{$t('settings.task_types')}}
        v-data-table(
          :headers="headers"
          :items="settings.taskTypes"
          :hide-default-footer="1"
          dense
        item-class="task-types"
        )
          template(v-slot:item.name="props")
            v-text-field(dense full-width v-model="props.item.name" @blur="update" filled :rules="[required]")
          template(v-slot:item.icon="props")
            v-text-field(dense full-width v-model="props.item.icon" @blur="update" filled :rules="[required]")
</template>
<script>

export default {
  name: 'globalSettings',
  components: {
  },
  data () {
    return {
      settings: {}
    }
  },
  computed: {
    headers () {
      return [{ text: 'label', value: 'name', sortable: false }, { text: 'icon', value: 'icon', sortable: false }]
    }
  },
  mounted () {
    this.settings = { taskTypes: this.getDefaultTypes() }
  },
  methods: {
    update () {
    },
    required (v) {
      return !!v || this.$t('messages.required_field')
    },
    getDefaultTypes () {
      return [
        { value: 'type0', name: 'info', icon: 'mdi-alpha-i-box' },
        { value: 'type1', name: 'idea', icon: 'mdi-lightbulb-on-outline' },
        { value: 'type2', name: 'bug', icon: 'mdi-bug-outline' },
        { value: 'type3', name: 'work', icon: 'mdi-tools' },
        { value: 'type4', name: 'question', icon: 'mdi-comment-question-outline' }
      ]
    }
  }
}
</script>

<style>
.v-icon {
  cursor: pointer;
}

</style>
