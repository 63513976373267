import Vue from 'vue'
import VueRouter from 'vue-router'
import Kanban from '@/components/kanban/Kanban'
import AbstractTable from '@/components/AbstractTable'
import AbstractEntityForm from '@/components/AbstractEntityForm'
import AppSettingsForm from '@/components/AppSettingsForm'
import ProjectClient from '../models/ProjectClient'
import Project from '../models/Project'
import ProjectTask from '../models/ProjectTask'
import ProjectsKanboard from '../models/ProjectsKanboard'
import Knowledgebase from '../components/Knowledgebase'
import Med from '../models/Med'
import MedTable from '../components/MedTable'
import Login from '../components/Login'
import Logout from '../components/Logout'
import MedsChooser from '../components/MedsChooser'
import GlobalClientSettings from '../components/GlobalClientSettings'
import EventHistory from '../components/EventHistory'
import AdminSettings from '../components/AdminSettings'
Vue.use(VueRouter)
export const models = [
  ProjectClient,
  Project,
  ProjectTask,
  ProjectsKanboard,
  Med]
Vue.prototype.$models = models
const routes = [
  // { name: 'home', path: '/', redirect: '/kanban/prio' },
  { name: 'login', path: '/login', component: Login },
  { name: 'logout', path: '/logout', component: Logout },

  { name: 'settings', path: '/settings', component: AppSettingsForm },
  { name: 'knowledge', path: '/knowledge', component: Knowledgebase },
  { name: 'medSettings', path: '/medsettings', component: MedsChooser },
  { name: 'clientSettings', path: '/clientsettings', component: GlobalClientSettings },

  {
    name: 'kanbanprio',
    path: '/kanban',
    component: Kanban,
    children: [
      { name: 'kanban', path: '/kanban/:id', props: true, component: Kanban }
    ]
  }
]
models.forEach(function (model) {
  const pathname = model.type.replace(/_/g, '-')
  routes.push({ name: pathname, path: '/' + pathname, component: AbstractTable, props: { model: model } })
  routes.push({
    name: 'edit-' + pathname,
    path: '/' + pathname + '/edit/:id',
    props: { model: model },
    component: AbstractEntityForm
  })
})

routes.push({ name: 'medtable', path: '/medtable', component: MedTable, props: { model: Med } })
routes.push({ name: 'admin', path: '/admin', component: AdminSettings })
routes.push({ name: 'eventHistory', path: '/history', component: EventHistory })

const router = new VueRouter({
  mode: process.env.VUE_APP_ROUTER_MODE, // abstract
  base: process.env.BASE_URL,
  routes
})

export default router
