<template lang="pug">
  div
    v-text-field.text-sm-body-2.title-edit(
      dense single-line
      :autofocus="editrecord.hasOwnProperty('isNew')"
      @mousedown.native.stop='()=>{return false}'
      @focus="onTitleFocus"
      v-model='editrecord.name'
      :rules="[rules.required]"
      :placeholder="$t('kanban.title')")
    v-textarea(
      outlined
      class=
      dense rows='2' auto-grow
    @mousedown.native.stop='()=>{return false}'
      :name="'desc' + editrecord._id"
      class="kanban-textarea"
      :autofocus="!editrecord.hasOwnProperty('isNew')"
      v-model='editrecord.description'
      :placeholder="$t('kanban.description')"
      @keydown.enter.exact="filterEnter"
      @keyup.enter.exact="filterEnter"
    )
    div(style="display: flex; flex-direction: column;align-items: center; padding-top:0")
      v-btn-toggle.mb-2.transparent(v-model="editrecord.progress" @mousedown.native.stop='()=>{return false}' )
        v-btn(v-for="progress in progressList " :value="progress.value" @click.stop="setProgress(progress.value)" dense text )
          v-icon() {{progress.icon}}
      v-btn-toggle.transparent( v-model="editrecord.taskType" @mousedown.native.stop='()=>{return false}' )
        v-btn(v-for="typeItem in typesList "  :value="typeItem.value" dense text   )
          v-icon() {{typeItem.icon}}
      v-radio-group.nowrap(
        row
        class="urgency"
        @mousedown.native.stop='()=>{return false}'
        v-model='editrecord.urgency'
      )
        v-radio(
          v-for='urgency in urgencyList'
          :ref='urgency'
          :key='urgency.val'
          :active-class="urgency.class"
          :class="urgency.class"
          :color="urgency.class"
      )
      v-select(outlined :label="$t('project.project')" dense v-model="editrecord.project_uuid" :items="projectsList" item-text="name" item-value="_id"  @mousedown.native.stop='()=>{return false}')
      v-btn.error--text.float-left( dense text @click.native.stop="removeRecord"  ) delete

</template>

<script>
import ProjectTask from '../../models/ProjectTask.js'
import { validation } from '@/utils'
export default {
  name: 'kanban-task-card-edit',
  props: {
    editrecord: {
      default: function () {},
      type: Object
    }
  },
  async mounted () {
    this.projectsList = await ProjectTask.getProjectList(this.$pouch)
  },
  data () {
    return {
      dialog: false,
      projectsList: [],
      typesList: ProjectTask.getTypesList(),
      urgencyList: ProjectTask.getUrgenciesList(),
      progressList: ProjectTask.getProgressList()

    }
  },
  computed: {
    rules () {
      return validation
    }
  },
  methods: {
    removeRecord () {
      this.remove(this.editrecord)
    },
    setProgress (p) {
      this.$nextTick(() => {
        if (this.editrecord.progress === undefined) {
          setTimeout(() => { this.editrecord.progress = p }, 100)
        }
      })
    },
    onTitleFocus (ev) {
      // eslint-disable-next-line no-prototype-builtins
      if (this.editrecord.hasOwnProperty('isNew')) {
        ev.target.select()
      }
    }
  }
}
</script>
<style>
.title-edit input{
  font-weight: bold !important;
}
</style>
