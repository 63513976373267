import { v4 as uuid } from 'uuid'
import { getNow } from '@/utils'

export default class EventItem {
  static type = 'event_item';

  constructor (aggrId, docType, eventKey, data) {
    this._id = uuid()
    this.aggrId = aggrId
    this.type = docType
    this.eventKey = eventKey
    this.data = data
    this.author = ''
    this.datetime = getNow().toISOString()
  }
}
